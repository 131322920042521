import React, { useEffect, useState } from "react";
import {
  getEntitlementsGroupsById,
  saveEntitlementsGroups,
} from "../../../api/entitlements";
import { ALERT_TYPES } from "../../../constants/appConstants";
import { createNotification } from "../../../util/notificationManager";
import { Modal } from "../../shared/modal/Modal";
import {
  ContArrows,
  ContOneOption,
  ContOptions,
  ContStaff,
  SubTitle,
} from "../styles";

export const EntitlementAllocation = ({ open, close, groupId }) => {
  const [entitlementAllSelected, setEntitlementAllSelected] = useState([]);
  const [entitlementAllocatedSelected, setEntitlementAllocatedSelected] =
    useState([]);
  const [entitlementAllocated, setEntitlementAllocated] = useState([]);
  const [allEntitlements, setAllEntitlements] = useState([]);

  //Functions
  const addAllocatedEntitlements = () => {
    const temp = [...entitlementAllSelected, ...entitlementAllocated];
    setEntitlementAllocated(temp);
    const filteredData = allEntitlements.filter(
      (item) =>
        !entitlementAllSelected.some(
          (element) => element.entitlementId === item.entitlementId,
        ),
    );
    setAllEntitlements(filteredData);
    setEntitlementAllSelected([]);
  };

  const addAllEntitlements = () => {
    const temp = [...entitlementAllocatedSelected, ...allEntitlements];
    setAllEntitlements(temp);
    const filteredData = entitlementAllocated.filter(
      (item) =>
        !entitlementAllocatedSelected.some(
          (element) => element.entitlementId === item.entitlementId,
        ),
    );
    setEntitlementAllocated(filteredData);
    setEntitlementAllocatedSelected([]);
  };

  const addAllEntitlementsSelected = (object) => {
    const index = entitlementAllSelected.findIndex(
      (item) => item.entitlementId === object.entitlementId,
    );
    if (index !== -1) {
      const newData = [...entitlementAllSelected];
      newData.splice(index, 1);
      setEntitlementAllSelected(newData);
    } else {
      setEntitlementAllSelected((prevData) => [...prevData, object]);
    }
  };

  const addAllocatedEntitlementsSelected = (object) => {
    const index = entitlementAllocatedSelected.findIndex(
      (item) => item.entitlementId === object.entitlementId,
    );
    if (index !== -1) {
      const newData = [...entitlementAllocatedSelected];
      newData.splice(index, 1);
      setEntitlementAllocatedSelected(newData);
    } else {
      setEntitlementAllocatedSelected((prevData) => [...prevData, object]);
    }
  };

  const saveChanges = () => {
    const data = {
      group: groupId,
      ids: entitlementAllocated.map((item) => item.entitlementId),
    };

    saveEntitlementsGroups(data)
      .then((res) => {
        if (res.status === 200) {
          // createNotification(
          //   ALERT_TYPES.SUCCESS,
          //   "Entitlement has been updated"
          // );
          close();
        }
      })
      .catch((e) => {
        createNotification(ALERT_TYPES.ERROR, e);
      });
  };
  //////////

  useEffect(() => {
    getEntitlementsGroupsById(groupId)
      .then((res) => {
        setAllEntitlements(
          !res.assigned
            ? res.all
            : res.all.filter(
                (item) => !res?.assigned?.includes(item.entitlementId),
              ),
        );
        const assignedEnt = res.all.filter((item) =>
          res?.assigned?.includes(item.entitlementId),
        );

        setEntitlementAllocated(assignedEnt);
      })
      .catch((e) => {
        console.log("Error", e);
      });
  }, []);

  return (
    <Modal
      open={open}
      close={close}
      title="Entitlement Allocation"
      width="max-content"
    >
      <ContStaff>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <SubTitle center className="mb-3">
            All Entitlements
          </SubTitle>

          <ContOptions>
            {allEntitlements.map((item, i) => (
              <ContOneOption
                selected={entitlementAllSelected.includes(item)}
                onClick={() => addAllEntitlementsSelected(item)}
              >
                <SubTitle key={item.entitlementId}>{item.entitlement}</SubTitle>
              </ContOneOption>
            ))}
          </ContOptions>
        </div>

        <ContArrows>
          <img
            src="./images/chevron-right-grey.svg"
            alt=""
            onClick={addAllocatedEntitlements}
          />
          <img
            src="./images/chevron-left-grey.svg"
            alt=""
            onClick={addAllEntitlements}
          />
        </ContArrows>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <SubTitle center className="mb-3">
            Allocated Entitlements
          </SubTitle>

          <ContOptions>
            {entitlementAllocated.map((item, i) => (
              <ContOneOption
                selected={entitlementAllocatedSelected.includes(item)}
                onClick={() => addAllocatedEntitlementsSelected(item)}
              >
                <SubTitle key={item.entitlementId}>{item.entitlement}</SubTitle>
              </ContOneOption>
            ))}
          </ContOptions>
        </div>
      </ContStaff>

      <div className="row justify-content-center mt-5">
        <button className="button submit col-3 mr-4" onClick={saveChanges}>
          <span className="text">SAVE</span>
        </button>

        <button className="button basic col-3" onClick={close}>
          CANCEL
        </button>
      </div>
    </Modal>
  );
};
