import React from "react";
import CreateStaffForm from "./create-staff-form/createStaffForm";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createUser, updateUser } from "../../../redux/users/userActions";
import { getQueryParameter } from "../../../util/url";
import { clone, get, isEmpty, isEqual, isNull, isUndefined, remove } from "lodash";
import { ALERT_TYPES, DATE_FORMAT } from "../../../constants/appConstants";
import { NotificationManager } from "react-notifications";

import { getALlRoleGroupList, getUserById } from "../../../api/user";
import moment from "moment";

class CreateStaff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: null,
      isEditMode: false,
      selectedUserId: "",
      hasRelevantDegree: false,
      isAuthorized: false,
      staffType: 2,
      credentials: [],
      routeToStaff: false,
      fileList: [],
      status: 4,
      roleGroupList: [],
      userRoleGroup: 0,
      billingState: "Pennsylvania",
      billingCountry: "USA"
    };
  }

  componentDidMount() {
    const userId = getQueryParameter("id");
    const userType = getQueryParameter("staffType");
    if (!isEmpty(userType) && !isUndefined(userType)) {
      this.setState({
        staffType: userType
      });
    }
    if (!isEmpty(userId) && !isUndefined(userId)) {
      getUserById(userId).then((res) => {
        if (res.credentials) {
          res.credentials = res.credentials.split(",");
        } else {
          res.credentials = [];
        }
        if (res.files === undefined) {
          res.files = [];
        }
        const user = this.mapUser(res);
        user.dob = user.dob
          ? new Date(moment(user.dob).utc().format(DATE_FORMAT))
          : null;
        user.hireDate = user.hireDate
          ? new Date(moment(user.hireDate).utc().format(DATE_FORMAT))
          : null;
        this.setState({
          initialValues: user,
          isEditMode: true,
          selectedUserId: userId,
          staffType: res.userType,
          hasRelevantDegree: res.hasRelevantDegree,
          isAuthorized: res.isAuthorized,
          credentials: res.credentials,
          fileList: res.files,
          status: res.status
        });
      });
    }

    getALlRoleGroupList().then((res) => {
      this.setState({
        roleGroupList: res.data
      });
    });
  }

  createUser = (values, routeToStaff) => {
    this.setState({
      routeToStaff
    });
    values["title"] = this.state.status;
    let user = this.mapUser(values);
    user.dob = user.dob
      ? moment(new Date(user.dob)).format("YYYY-MM-DD")
      : null;
    user.hireDate = user.hireDate
      ? moment(new Date(user.hireDate)).format("YYYY-MM-DD")
      : null;
    user.files = this.state.fileList;
    if (!this.state.isEditMode) {
      this.props.dispatch(createUser(user));
    } else {
      user["userId"] = parseInt(this.state.selectedUserId);
      this.props.dispatch(updateUser(user));
    }
  };

  componentWillReceiveProps = (nextProps) => {
    const { history } = this.props;
    if (
      !isNull(get(nextProps, "createUserSuccess")) &&
      !isEqual(nextProps.createUserSuccess, this.props.createUserSuccess)
    ) {
      this.createNotification(ALERT_TYPES.SUCCESS, "Staff create successful");
      if (this.state.routeToStaff) {
        history.push(
          `/admin/view-staff?id=${nextProps.createUserSuccess.userId}`
        );
      } else {
        history.push("/admin/staff-type");
      }
    }

    if (
      !isNull(get(nextProps, "createUserFail")) &&
      !isEqual(nextProps.createUserFail, this.props.createUserFail)
    ) {
      this.createNotification(
        ALERT_TYPES.ERROR,
        nextProps.createUserFail.message
      );
    }

    if (
      !isNull(get(nextProps, "updateUserSuccess")) &&
      !isEqual(nextProps.updateUserSuccess, this.props.updateUserSuccess)
    ) {
      this.createNotification(ALERT_TYPES.SUCCESS, "Staff update successful");
      history.push(`/admin/view-staff?id=${this.state.selectedUserId}`);
    }

    if (
      !isNull(get(nextProps, "updateUserFail")) &&
      !isEqual(nextProps.updateUserFail, this.props.updateUserFail)
    ) {
      this.createNotification(
        ALERT_TYPES.ERROR,
        nextProps.updateUserFail
          ? nextProps.updateUserFail
          : "Staff update failed"
      );
    }
  };

  createNotification(alertType, alertMessage) {
    switch (alertType) {
      case ALERT_TYPES.INFO:
        NotificationManager.info(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.SUCCESS:
        NotificationManager.success(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.WARNING:
        NotificationManager.warning(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.ERROR:
        NotificationManager.error(alertMessage, "", 2000);
        break;
      default:
        break;
    }
  }

  mapUser = (userData) => {
    let client = {
      firstName: get(userData, "firstName", ""),
      middleName: get(userData, "middleName", ""),
      lastName: get(userData, "lastName", ""),
      email: get(userData, "email", ""),
      password: get(userData, "password", ""),
      userType: parseInt(this.state.staffType),
      reportTo: 0,
      salutation: get(userData, "salutation", ""),
      suffix: get(userData, "suffix", ""),
      dob: get(userData, "dob", null),
      hireDate: get(userData, "hireDate", null),
      title: "",
      ssn_tin: this.getSSN(get(userData, "ssn_tin", ""), "-", ""),
      phone: get(userData, "phone", ""),
      mobile: get(userData, "mobile", ""),
      memo: get(userData, "memo", ""),
      hasRelevantDegree: this.state.hasRelevantDegree,
      credentials: this.state.credentials.toLocaleString(),
      percentagePaid: parseFloat(get(userData, "percentagePaid", 0)),
      billingStreet: get(userData, "billingStreet", ""),
      billingCity: get(userData, "billingCity", ""),
      billingState: get(userData, "billingState", ""),
      billingZIP: get(userData, "billingZIP", ""),
      billingCountry: get(userData, "billingCountry", ""),
      orgAuthentication: get(userData, "orgAuthentication", ""),
      isAuthorized: this.state.isAuthorized,
      status: parseInt(this.state.status)
    };
    return client;
  };

  getSSN = (ssn) => {
    if (ssn) {
      return ssn.replace(/[^a-zA-Z 0-9]+/g, "");
    }
    return ssn;
  };

  render() {
    return (
      <div className="client-client">
        <div className="row">
          <div
            className="row col-lg-2 col-md-2 col-sm-4"
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <img src="./images/chevron-left-green.svg" alt="" />
            <span
              style={{
                fontFamily: "Muli",
                color: "#333333",
                fontSize: 15,
                padding: 10
              }}
            >
              Back
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 page-title">
            {!this.state.isEditMode
              ? "Create Staff Member"
              : "Edit Staff Member"}
          </div>
        </div>
        <div className="row">
          <CreateStaffForm
            onSubmit={this.createUser}
            userType={this.state.staffType}
            credentials={this.state.credentials}
            initialValues={this.state.initialValues}
            hasRelevantDegree={this.state.hasRelevantDegree}
            isEditMode={this.state.isEditMode}
            staffStatus={this.state.status}
            isAuthorized={this.state.isAuthorized}
            roleGroupList={this.state.roleGroupList}
            userRoleGroup={this.state.userRoleGroup}
            billingState={this.state.billingState}
            billingCountry={this.state.billingCountry}
            onHasRelevantDegree={(value) => {
              this.setState({ hasRelevantDegree: value });
            }}
            onIsAuthorized={(value) => {
              this.setState({ isAuthorized: value });
            }}
            onCredentialsSelect={(value) => {
              this.setState({ credentials: value });
            }}
            onStatusChange={(status) => {
              this.setState({ status: status });
            }}
            onRoleGroupChange={(rg) => {
              this.setState({ userRoleGroup: rg });
            }}
            onChange={this.handleFileChange}
            fileList={this.state.fileList}
            onFileUpload={(value, success) => {
              if (success) {
                let files = clone(this.state.fileList);
                files.push(value);
                this.createNotification(ALERT_TYPES.INFO, "File uploaded.");
                this.setState({
                  fileList: files
                });
              } else {
                this.createNotification(
                  ALERT_TYPES.WARNING,
                  "File upload fail."
                );
              }
            }}
            onRemoveImage={(documentKey) => {
              let files = clone(this.state.fileList);
              remove(files, function(o) {
                return o.documentKey == documentKey;
              });
              this.setState({
                fileList: files
              });
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  createUserSuccess: state.user.createSuccess,
  createUserFail: state.user.createFail,
  updateUserSuccess: state.user.updateSuccess,
  updateUserFail: state.user.updateFail,
  users: state.user.getAllSuccess
});

export default withRouter(connect(mapStateToProps)(CreateStaff));
