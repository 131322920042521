import React from "react";
import BasicHeader from "../shared/data-grid/custom-headers/basic-header";
import BasicCell from "../shared/data-grid/custom-cells/basic-cell";
import DataGrid from "../shared/data-grid/data-grid";
import { isEmpty, isEqual, isNull, map, startCase } from "lodash";
import { getAllWorkEntries } from "../../redux/workEntries/workEntryAction";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../shared/data-grid/custom-cells/actions";
import View from "../shared/data-grid/custom-cells/view";
import "./workEntries.scss";
import { getAllUsersList } from "../../api/user";
import { getClientServicesList } from "../../api/clientService";
import { isMobileDevice } from "../../util/deviceType";
import WECard from "./weCard";
import Pagination from "../shared/data-grid/pagination/pagination";
import DatePickerComponent from "../shared/date-picker/datePickerComponent";
import moment from "moment";
import { saveWESearchChanges } from "../../redux/config/configAction";
import { DATEFILTEROPTIONS, VISIT_STATUS } from "../../constants/appConstants";
import SelectCell from "../shared/data-grid/custom-cells/select-cell";
import SearchableDropdown from "../shared/searchable-dropdown";
import { isAuthorized } from "../../util/entitlementManager";
import { ENTITLEMENTS } from "../../constants/entitlements";

class WorkEntries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workEntries: [],
      selectedWorkEntryId: 0,
      pageNumber: 0,
      searchParam: "",
      filter: "recent",
      pageSize: 10,
      totalWorkEntries: 0,
      usersList: [],
      servicesList: [],
      selectedStaff: 0,
      selectedService: 0,
      fromDate: new Date(new Date().setDate(new Date().getDate() - 7)).setHours(
        0,
        0,
        0,
        0,
      ),
      toDate: new Date().setHours(0, 0, 0, 0),
      dateRangeOption: 1,
      activeTabIndex: 1,
      statusList: [],
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { workEntries, searchConfig } = this.props;
    const workEntryData = [];
    if (
      !isEqual(nextProps.workEntries, workEntries) &&
      !isNull(nextProps.workEntries)
    ) {
      map(nextProps.workEntries.data, (we) => {
        workEntryData.push({
          id: we.entryId,
          name: we.entryName,
          staffName: startCase(we.userName),
          clientName: startCase(we.clientName),
          startTime: moment(new Date(we.startTime)).format(
            "MM-DD-YYYY HH:mm A",
          ),
          endTime:
            we.status !== VISIT_STATUS.IN_PROGRESS
              ? moment(new Date(we.endTime)).format("MM-DD-YYYY HH:mm A")
              : "-",
          elapsedHours:
            we.status == VISIT_STATUS.COMPLETED
              ? we.elapsedTime.toFixed(2)
              : "-",
          usedUnits: we.status == VISIT_STATUS.COMPLETED ? we.unitsUsed : "-",
          gross:
            we.status == VISIT_STATUS.COMPLETED
              ? we.grossAmount.toFixed(2)
              : "-",
          net:
            we.status == VISIT_STATUS.COMPLETED ? we.netAmount.toFixed(2) : "-",
          service: we.serviceName,
          adminVerification: we.adminVerification,
        });
      });
      this.setState({
        workEntries: workEntryData,
        totalWorkEntries: nextProps.workEntries.recordCount,
      });
    }

    if (
      !isNull(nextProps.searchConfig) &&
      !isEmpty(nextProps.searchConfig) &&
      !isEqual(nextProps.searchConfig, searchConfig)
    ) {
      this.setState({
        pageNumber: nextProps.searchConfig.pageNumber,
        pageSize: nextProps.searchConfig.pageSize,
        filter: nextProps.searchConfig.filter,
        searchParam: nextProps.searchConfig.searchParam,
        selectedStaff: nextProps.searchConfig.selectedStaff,
        selectedService: nextProps.searchConfig.selectedService,
        fromDate: nextProps.searchConfig.fromDate,
        toDate: nextProps.searchConfig.toDate,
        dateRangeOption: nextProps.searchConfig.dateRangeOption,
        activeTabIndex: nextProps.searchConfig.activeTabIndex,
      });
      this.getAllWorkEntries(
        nextProps.searchConfig.pageNumber,
        nextProps.searchConfig.pageSize,
        nextProps.searchConfig.filter,
        nextProps.searchConfig.searchParam,
        nextProps.searchConfig.selectedStaff,
        nextProps.searchConfig.selectedService,
        nextProps.searchConfig.fromDate,
        nextProps.searchConfig.toDate,
        nextProps.searchConfig.dateRangeOption,
        nextProps.searchConfig.activeTabIndex,
      );
    }
  }

  componentDidMount() {
    this.getAllServices();
    this.getAllStaff();
    const { searchConfig } = this.props;
    if (searchConfig) {
      this.setState({
        pageNumber: searchConfig.pageNumber,
        pageSize: searchConfig.pageSize,
        filter: searchConfig.filter,
        searchParam: searchConfig.searchParam,
        selectedStaff: searchConfig.selectedStaff,
        selectedService: searchConfig.selectedService,
        fromDate: searchConfig.fromDate,
        toDate: searchConfig.toDate,
        dateRangeOption: searchConfig.dateRangeOption,
        activeTabIndex: searchConfig.activeTabIndex,
      });
      this.getAllWorkEntries(
        searchConfig.pageNumber,
        searchConfig.pageSize,
        searchConfig.filter,
        searchConfig.searchParam,
        searchConfig.selectedStaff,
        searchConfig.selectedService,
        searchConfig.fromDate,
        searchConfig.toDate,
        searchConfig.dateRangeOption,
        searchConfig.activeTabIndex,
      );
    } else {
      this.getAllWorkEntries(
        this.state.pageNumber,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.selectedStaff,
        this.state.selectedService,
        this.state.fromDate,
        this.state.toDate,
        this.state.dateRangeOption,
        this.state.activeTabIndex,
      );
    }
  }

  getAllWorkEntries = (
    pageNumber,
    pageSize,
    filter,
    searchParams,
    staff,
    service,
    fromDate,
    toDate,
    dateRangeOption,
    activeTabIndex,
  ) => {
    let from = moment.utc(fromDate).format("YYYY-MM-DD HH:mm:ss");
    let to = moment.utc(toDate).format("YYYY-MM-DD HH:mm:ss");
    let entryType = "";
    switch (activeTabIndex) {
      case 0:
        entryType = "all";
        break;
      case 1:
        entryType = "com";
        break;
      case 2:
        entryType = "inp";
        break;
      case 3:
        entryType = "abd";
        break;
    }
    this.props.dispatch(
      getAllWorkEntries(
        pageNumber,
        pageSize,
        filter,
        searchParams,
        entryType,
        staff,
        service,
        from,
        to,
        dateRangeOption,
      ),
    );
  };

  onCreateNewWorkEntry = () => {
    this.props.history.push("/admin/create-timeSheet");
  };

  onEdit = (workEntryId) => {
    this.props.history.push(`/admin/edit-timeSheet?id=${workEntryId}`);
  };

  onPageChange = (pageNumber) => {
    this.setState({
      pageNumber: pageNumber,
    });
    this.props.dispatch(
      saveWESearchChanges(
        pageNumber,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.selectedStaff,
        this.state.selectedService,
        this.state.fromDate,
        this.state.toDate,
        this.state.dateRangeOption,
        this.state.activeTabIndex,
      ),
    );
  };

  onPageSizeChange = (pageSize) => {
    this.setState({
      pageSize: pageSize,
    });
    this.props.dispatch(
      saveWESearchChanges(
        0,
        pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.selectedStaff,
        this.state.selectedService,
        this.state.fromDate,
        this.state.toDate,
        this.state.dateRangeOption,
        this.state.activeTabIndex,
      ),
    );
  };

  onSearch = (event) => {
    const searchParam = event.target.value;
    if (searchParam.length >= 3 || searchParam.length == 0) {
      this.props.dispatch(
        saveWESearchChanges(
          0,
          this.state.pageSize,
          "recent",
          searchParam,
          0,
          0,
          this.state.fromDate,
          this.state.toDate,
          this.state.dateRangeOption,
          this.state.activeTabIndex,
        ),
      );
    }
    this.setState({
      searchParam,
      pageNumber: 0,
      filter: "recent",
      selectedStaff: 0,
      selectedService: 0,
    });
  };

  onPressEnter = (event) => {
    if (event.keyCode == 13) {
      this.props.dispatch(
        saveWESearchChanges(
          0,
          this.state.pageSize,
          this.state.filter,
          this.state.searchParam,
          this.state.selectedStaff,
          this.state.selectedService,
          this.state.fromDate,
          this.state.toDate,
          this.state.dateRangeOption,
          this.state.activeTabIndex,
        ),
      );
    }
  };

  onFilterChange = (event) => {
    const filter = event.target.value;
    this.setState({
      filter,
    });
    this.props.dispatch(
      saveWESearchChanges(
        0,
        this.state.pageSize,
        filter,
        this.state.searchParam,
        this.state.selectedStaff,
        this.state.selectedService,
        this.state.fromDate,
        this.state.toDate,
        this.state.dateRangeOption,
        this.state.activeTabIndex,
      ),
    );
  };

  onDateRangeChange = (event) => {
    const dateRangeOption = parseInt(event.target.value);
    this.setState({
      dateRangeOption,
    });
    this.props.dispatch(
      saveWESearchChanges(
        0,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.selectedStaff,
        this.state.selectedService,
        this.state.fromDate,
        this.state.toDate,
        dateRangeOption,
        this.state.activeTabIndex,
      ),
    );
  };

  onDetails = (workEntryId) => {
    this.props.history.push(`/admin/view-timeSheet?id=${workEntryId}`);
  };

  getAllServices() {
    getClientServicesList()
      .then((res) => {
        this.setState({
          servicesList: res,
        });
      })
      .catch((err) => {
        console.log("get all services error ", err);
      });
  }

  getAllStaff() {
    getAllUsersList()
      .then((res) => {
        this.setState({
          usersList: res,
        });
      })
      .catch((err) => {
        console.log("get all staff error ", err);
      });
  }

  onStaffChange = (value) => {
    this.setState({ selectedStaff: value });
    this.props.dispatch(
      saveWESearchChanges(
        0,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        value,
        this.state.selectedService,
        this.state.fromDate,
        this.state.toDate,
        this.state.dateRangeOption,
        this.state.activeTabIndex,
      ),
    );
  };

  onServiceChange = (value) => {
    this.setState({ selectedService: value });
    this.props.dispatch(
      saveWESearchChanges(
        0,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.selectedStaff,
        value,
        this.state.fromDate,
        this.state.toDate,
        this.state.dateRangeOption,
        this.state.activeTabIndex,
      ),
    );
  };

  onFromDateChange = (date) => {
    this.setState({
      fromDate: date,
    });
    this.props.dispatch(
      saveWESearchChanges(
        0,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.selectedStaff,
        this.state.selectedService,
        date,
        this.state.toDate,
        this.state.dateRangeOption,
        this.state.activeTabIndex,
      ),
    );
  };

  onToDateChange = (date) => {
    this.setState({
      toDate: date,
    });
    this.props.dispatch(
      saveWESearchChanges(
        0,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.selectedStaff,
        this.state.selectedService,
        this.state.fromDate,
        date,
        this.state.dateRangeOption,
        this.state.activeTabIndex,
      ),
    );
  };

  onTabChange = (tab) => {
    this.setState({ activeTabIndex: tab });
    this.props.dispatch(
      saveWESearchChanges(
        0,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.selectedStaff,
        this.state.selectedService,
        this.state.fromDate,
        this.state.toDate,
        this.state.dateRangeOption,
        tab,
      ),
    );
  };

  render() {
    const columns = [
      {
        Header: () => <BasicHeader header={"Work Entry Name"} />,
        accessor: "name",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
        width: 170,
      },
      {
        Header: () => <BasicHeader header={"Staff Name"} />,
        accessor: "staffName",
        Cell: (row) => {
          return <BasicCell value={row.value} index={row.index} />;
        },
      },
      {
        Header: () => <BasicHeader header={"Client Name"} />,
        accessor: "clientName",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
      },
      {
        Header: () => <BasicHeader header={"Start Time"} />,
        accessor: "startTime",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
        width: 160,
      },
      {
        Header: () => <BasicHeader header={"End Time"} />,
        accessor: "endTime",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
        width: 160,
      },
      {
        Header: () => <BasicHeader header={"Elapsed Hrs"} />,
        accessor: "elapsedHours",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
        width: 100,
      },
      {
        Header: () => <BasicHeader header={"Used Units"} />,
        accessor: "usedUnits",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
        width: 100,
      },
      {
        Header: () => <BasicHeader header={"Gross ($)"} />,
        accessor: "gross",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
        width: 100,
      },
      {
        Header: () => <BasicHeader header={"Net ($)"} />,
        accessor: "net",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
        width: 100,
      },
      {
        Header: () => <BasicHeader header={"Service"} />,
        accessor: "service",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
      },
    ];
    if (isAuthorized(ENTITLEMENTS.UpdateWorkEntries)) {
      columns.push({
        Header: "",
        accessor: "icon",
        Cell: (row) => (
          <Actions
            actionId={row.original.id}
            onEdit={this.onEdit.bind(row.original.id)}
          />
        ),
        width: 30,
      });
    }
    if (isAuthorized(ENTITLEMENTS.ViewWorkEntryDetails)) {
      columns.push({
        Header: "",
        accessor: "icon",
        Cell: (row) => (
          <View
            actionId={row.original.id}
            onClick={this.onDetails.bind(row.original.id)}
          />
        ),
        width: 30,
      });
    }
    if (this.state.activeTabIndex === 1) {
      columns.splice(9, 0, {
        Header: () => <BasicHeader header={""} />,
        accessor: "adminVerification",
        Cell: (row) => <SelectCell value={row.value} index={row.index} />,
        width: 30,
      });
    }

    return (
      <div className="table-layout">
        <div className="row col-12 header-container">
          <div className="row col-lg-10 col-sm-12">
            <span className="col-lg-3 col-md-4 col-sm-12 title">
              Work Entries
            </span>
            <span className="col-lg-1 col-md-2 col-sm-2 sub-title">Search</span>
            <div className="col-lg-4 col-md-6 col-sm-10 input-box">
              <input
                type="text"
                className="box text-box search-icon"
                value={this.state.searchParam}
                onChange={this.onSearch}
                onKeyDown={this.onPressEnter}
                placeholder={"Work Entry Name"}
              />
              {/*<FeatherIcon icon="search"/>*/}
            </div>
          </div>
          <div className="col-lg-2 col-sm-12">
            <button
              className="button submit"
              onClick={this.onCreateNewWorkEntry}
            >
              <span className="text">CREATE NEW</span>
            </button>
          </div>
        </div>

        <div
          className="row list-container"
          style={{
            backgroundColor: "#FFF",
            border: "1px solid #E0E1E2",
            margin: 20,
            padding: 20,
          }}
        >
          <div
            className="col-lg-2 input-box text-left"
            style={{ marginBottom: 0 }}
          >
            <div className="label">Range Selection</div>
            <div>
              <select
                className="select-box list-dropdown"
                onChange={this.onDateRangeChange}
                value={this.state.dateRangeOption}
              >
                <option value={DATEFILTEROPTIONS.CREATED}>Created Date</option>
                <option value={DATEFILTEROPTIONS.WESTART}>Start Date</option>
                <option value={DATEFILTEROPTIONS.WEEND}>End Date</option>
              </select>
            </div>
          </div>
          <div
            className="col-lg-2 input-box text-left"
            style={{ marginBottom: 0 }}
          >
            <div className="label">From Date</div>
            <DatePickerComponent
              selected={this.state.fromDate}
              onChange={this.onFromDateChange}
              dateFormat={"MM/DD/YYYY"}
              className={"box text-box calendar-icon"}
              maxDate={new Date()}
            />
          </div>
          <div
            className="col-lg-2 input-box text-left"
            style={{ marginBottom: 0 }}
          >
            <div className="label">To Date</div>
            <DatePickerComponent
              selected={this.state.toDate}
              onChange={this.onToDateChange}
              dateFormat={"MM/DD/YYYY"}
              className={"box text-box calendar-icon"}
              minDate={this.state.startDate}
              maxDate={new Date()}
            />
          </div>
          <div
            className="col-lg-2 input-box text-left"
            style={{ marginBottom: 0 }}
          >
            <div className="label">Staff Member</div>
            <SearchableDropdown
              options={this.state.usersList}
              value={this.state.selectedStaff}
              defaultValue={0}
              keyIdentifier="userId"
              valueIdentifier="name"
              placeholder="Search Staff Member"
              onChange={this.onStaffChange}
            />
          </div>
          <div
            className="col-lg-2 input-box text-left"
            style={{ marginBottom: 0 }}
          >
            <div className="label">Client Service</div>
            <SearchableDropdown
              options={this.state.servicesList}
              value={this.state.selectedService}
              defaultValue={0}
              keyIdentifier="serviceRequestId"
              valueIdentifier="serviceName"
              placeholder="Search Client Service"
              onChange={this.onServiceChange}
            />
          </div>
          <div
            className="col-lg-2 input-box text-left"
            style={{ marginBottom: 0 }}
          >
            <div className="label"> &nbsp;</div>
            <select
              className="select-box list-dropdown"
              onChange={this.onFilterChange}
              value={this.state.filter}
            >
              <option value="all">All Work Entries</option>
              <option value="recent">Recently Added</option>
            </select>
          </div>
        </div>

        <div className="body-container">
          <div className="col-lg-12 col-sm-12 tabbed-row">
            <div
              style={{ display: "inline-block" }}
              className={"col-lg-2 col-sm-3 text-center"}
              onClick={() => {
                this.onTabChange(1);
              }}
            >
              <div
                className={
                  this.state.activeTabIndex === 1
                    ? "tab_text_style completed-active-tab"
                    : "tab_text_style inactive_tabs"
                }
              >
                <img src="./images/completed-circle.svg" alt="" />
                <span>Completed</span>
              </div>
            </div>
            <div
              style={{ display: "inline-block", marginLeft: "0px" }}
              className={"col-lg-2 col-sm-3 text-center"}
              onClick={() => {
                this.onTabChange(2);
              }}
            >
              <div
                className={
                  this.state.activeTabIndex === 2
                    ? "tab_text_style inprogress-active-tab"
                    : "tab_text_style inactive_tabs"
                }
              >
                <img src="./images/inprogress.svg" alt="" />
                <span style={{}}>In Progress</span>
              </div>
            </div>
            <div
              style={{ display: "inline-block", marginLeft: "0px" }}
              className={"col-lg-2 col-sm-3 text-center"}
              onClick={() => {
                this.onTabChange(3);
              }}
            >
              <div
                className={
                  this.state.activeTabIndex === 3
                    ? "tab_text_style abandoned-active-tab"
                    : "tab_text_style inactive_tabs"
                }
              >
                <img src="./images/abandoned.svg" alt="" />
                <span>Abandoned</span>
              </div>
            </div>
          </div>
          <div className="data-grid-container">
            {!isMobileDevice() && (
              <div className="row">
                <div className="col-12">
                  <DataGrid
                    data={this.state.workEntries}
                    columns={columns}
                    onRowClick={(rowInfo) => {
                      // this.viewOrganization(get(rowInfo.original, 'id', ''))
                    }}
                    onPageSizeChange={this.onPageSizeChange}
                    onPageNumberChange={this.onPageChange}
                    total={this.state.totalWorkEntries}
                    paginationLabel={"Work Entries per page"}
                    showPagination={true}
                    pageNumber={this.state.pageNumber}
                    pageSize={this.state.pageSize}
                  />
                </div>
              </div>
            )}

            {isMobileDevice() && (
              <div className="row" style={{ margin: 10, marginTop: 20 }}>
                {map(this.state.workEntries, (we) => {
                  return (
                    <WECard
                      we={we}
                      onDetails={(id) => {
                        this.onDetails(id);
                      }}
                    />
                  );
                })}
                <div className="row pagination pagination-align">
                  <div className="col-12">
                    <Pagination
                      onPageSizeChange={this.onPageSizeChange}
                      label={"Work Entries per page"}
                      onPageNumberChange={this.onPageChange}
                      total={this.state.totalWorkEntries}
                      pageNumber={this.state.pageNumber}
                      pageSize={this.state.pageSize}
                    />
                  </div>
                </div>
              </div>
            )}

            {isMobileDevice() && this.state.workEntries.length === 0 && (
              <div className="row">
                <div className="col-12 text-center">
                  <span>No Work Entries</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  workEntries: state.workEntry.getAllSuccess,
  workEntryError: state.workEntry.getAllFail,
  searchConfig: state.config.weSearch,
});

export default withRouter(connect(mapStateToProps)(WorkEntries));
