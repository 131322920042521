import * as React from "react";
import { useHistory, withRouter } from "react-router-dom";
import Header from "../components/shared/header/header";
import SideNavigation from "../components/shared/side-navigation/side-navigation";
import Clients from "../components/clients/clients";
import "./appContainter.scss";
import CreateClient from "../components/clients/create-client/createClient";
import ClientDetails from "../components/clients/client-details/clientDetails";
import Staff from "../components/staff/staff";
import Dashboard from "../components/dashboard/dashboard";
import StaffType from "../components/staff/staff-type/staffType";
import CreateStaff from "../components/staff/create-staff/createStaff";
import WorkEntries from "../components/work-entries/workEntries";
import CreateTimeSheet from "../components/work-entries/create-timesheet/createTimeSheet";
import BillingReport from "../components/reports/billingReport";
import Profile from "../components/profile/profile";
import { ProtectedRoute } from "../components/shared/protected-route/protectedRoute";
import StaffDetails from "../components/staff/staff-details/staff-details";
import SupportCoordinator from "../components/support-cordinator/support-cordinator";
import CreateSC from "../components/support-cordinator/create-sc/createSC";
import SCDetails from "../components/support-cordinator/SC-details/SC-details";
import WorkEntryDetails from "../components/work-entries/work-entry-details/workEntryDetails";
import visits from "../components/visits/visits";
import {
  endImpersonation,
  getAuth,
  getAuthData,
  getImpersonationDetails,
  isImpersonationInProgress
} from "../util/auth";
import VisitStart from "../components/visits/create-visit/visit-init";
import VisitProgress from "../components/visits/visit-progress/visit-progress";
import FAQ from "../components/faq/faq";
import StaffReports from "../components/reports/report-views/staffReports";
import { USER_TYPES } from "../constants/user";
import { ServiceSummary } from "../components/reports/report-views/serviceSummary/serviceSummary";
import { OrganizationDetails } from "../components/organizations/organizationDetails/OrganizationDetails";
import { NestedRoutesOrg } from "../components/organizations/organizationDetails/NestedRoutes";
import { Organizations } from "../components/organizations/Organizations";
import { CreateOrganization } from "../components/organizations/createOrganization/CreateOrganization";
import { Entitlements } from "../components/entitlements/Entitlements";
import { NestedRoutesEnt } from "../components/entitlements/NestedRoutes";
import { stopImpersonation } from "../api/organizations";
import Services from "../components/services/services";
import { ImportServices } from "../components/services/services-import/import";

class AppContainer extends React.Component {

  constructor(props) {
    super(props);
    let userData = getAuth();
    let imp = isImpersonationInProgress();
    let username = "";
    if (userData != null) {
      username = userData.firstName + " " + userData.lastName;
    }

    this.state = {
      username: username,
      impersonation: imp
    };
  }

  onCancelClick = () => {
    let userData = getImpersonationDetails();
    stopImpersonation(userData.userId).then((res) => {
      this.setState({
        impersonation: false
      }, () => {
        endImpersonation();
      });
    });
  };

  render() {
    const userType = JSON.parse(getAuthData()).userType;

    return (
      <div>
        <base href="/" />
        <Header />

        <SideNavigation />

        {this.state.impersonation &&
          <div className="impersonation-bar">
            <label>You are impersonating {this.state.username}</label>
            <a href="#" role="button"
               onClick={(event) => {
                 event.preventDefault();
                 this.onCancelClick();
               }}
            >[ End Impersonation ]</a>
          </div>
        }


        <div className="content-wrapper app-container">

          <ProtectedRoute exact path="/" component={Dashboard} />

          {/* <ProtectedRoute exact path="/admin/clients" component={Clients} />

          <ProtectedRoute
            path="/admin/entitlements"
            component={NestedRoutesEnt}
          />

          <ProtectedRoute
            exact
            path="/admin/create-client"
            component={CreateClient}
          />
          <ProtectedRoute
            exact
            path="/admin/edit-client"
            component={CreateClient}
          />
          <ProtectedRoute
            exact
            path="/admin/client-details"
            component={ClientDetails}
          />
          <ProtectedRoute exact path="/admin/staff" component={Staff} />
          <ProtectedRoute
            exact
            path="/admin/support-coordinator"
            component={SupportCoordinator}
          />
          <ProtectedRoute
            exact
            path="/admin/staff-type"
            component={StaffType}
          />
          <ProtectedRoute
            exact
            path="/admin/create-staff"
            component={CreateStaff}
          />
          <ProtectedRoute exact path="/admin/create-sc" component={CreateSC} />
          <ProtectedRoute
            exact
            path="/admin/edit-staff"
            component={CreateStaff}
          />
          <ProtectedRoute
            exact
            path="/admin/view-staff"
            component={StaffDetails}
          />
          <ProtectedRoute exact path="/admin/edit-sc" component={CreateSC} />
          <ProtectedRoute exact path="/admin/view-sc" component={SCDetails} />
          <ProtectedRoute
            exact
            path="/admin/work-entries"
            component={WorkEntries}
          />
          <ProtectedRoute
            exact
            path="/admin/create-timeSheet"
            component={CreateTimeSheet}
          />
          <ProtectedRoute
            exact
            path="/admin/edit-timeSheet"
            component={CreateTimeSheet}
          />
          <ProtectedRoute
            exact
            path="/admin/view-timeSheet"
            component={WorkEntryDetails}
          />
          <ProtectedRoute path="/admin/reports" component={BillingReport} /> */}

          {userType === USER_TYPES.SUPER_ADMIN && (
            <>
              {/*<ProtectedRoute exact path="/admin/clients" component={Clients} />*/}

              <ProtectedRoute
                path="/admin/organizations/organization-details/:idOrg"
                component={NestedRoutesOrg}
              />

              <ProtectedRoute
                exact
                path="/admin/edit-client"
                component={CreateClient}
              />

              <ProtectedRoute
                exact
                path="/admin/client-details"
                component={ClientDetails}
              />

              <ProtectedRoute
                path="/admin/entitlements"
                component={NestedRoutesEnt}
              />


              <ProtectedRoute
                path="/admin/import-services"
                component={ImportServices}
              />

              <ProtectedRoute
                path="/admin/services"
                component={Services}
              />


            </>
          )}

          {(userType === USER_TYPES.SUPER_ADMIN ||
            userType === USER_TYPES.ADMIN) && (
            <>
              {/* Clients */}
              <ProtectedRoute exact path="/admin/clients" component={Clients} />

              <ProtectedRoute
                exact
                path="/admin/create-client"
                component={CreateClient}
              />

              <ProtectedRoute
                exact
                path="/admin/edit-client"
                component={CreateClient}
              />

              <ProtectedRoute
                exact
                path="/admin/client-details"
                component={ClientDetails}
              />

              {/* Staff */}
              <ProtectedRoute exact path="/admin/staff" component={Staff} />

              <ProtectedRoute
                exact
                path="/admin/staff-type"
                component={StaffType}
              />

              <ProtectedRoute
                exact
                path="/admin/create-staff"
                component={CreateStaff}
              />

              <ProtectedRoute
                exact
                path="/admin/create-sc"
                component={CreateSC}
              />

              <ProtectedRoute
                exact
                path="/admin/edit-staff"
                component={CreateStaff}
              />

              <ProtectedRoute
                exact
                path="/admin/view-staff"
                component={StaffDetails}
              />

              {/* Support Coordinator */}

              <ProtectedRoute
                exact
                path="/admin/support-coordinator"
                component={SupportCoordinator}
              />

              <ProtectedRoute
                exact
                path="/admin/edit-sc"
                component={CreateSC}
              />

              <ProtectedRoute
                exact
                path="/admin/view-sc"
                component={SCDetails}
              />

              {/* Work Entries */}

              <ProtectedRoute
                exact
                path="/admin/work-entries"
                component={WorkEntries}
              />

              <ProtectedRoute
                exact
                path="/admin/create-timeSheet"
                component={CreateTimeSheet}
              />

              <ProtectedRoute
                exact
                path="/admin/edit-timeSheet"
                component={CreateTimeSheet}
              />

              <ProtectedRoute
                exact
                path="/admin/view-timeSheet"
                component={WorkEntryDetails}
              />

              {/* ///// */}

              <ProtectedRoute path="/admin/reports" component={BillingReport} />

              <ProtectedRoute exact path="/admin/profile" component={Profile} />

              <ProtectedRoute exact path="/admin/visits" component={visits} />

              <ProtectedRoute exact path="/admin/faq" component={FAQ} />
            </>
          )}

          {userType === USER_TYPES.STAFF && (
            <>
              <ProtectedRoute exact path="/staff/visits" component={visits} />

              <ProtectedRoute exact path="/staff/profile" component={Profile} />

              <ProtectedRoute
                exact
                path="/staff/reports"
                component={StaffReports}
              />

              <ProtectedRoute
                exact
                path="/service/reports"
                component={ServiceSummary}
              />

              <ProtectedRoute
                exact
                path="/staff/start-visit"
                component={VisitStart}
              />

              <ProtectedRoute
                exact
                path="/staff/visit-progress"
                component={VisitProgress}
              />

              <ProtectedRoute exact path="/staff/faq" component={FAQ} />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(AppContainer);
