import * as React from "react";
import "./index.scss";
import SignatureCanvas from "react-signature-canvas";
import { isEqual, isNull } from "lodash";

class SignaturePad extends React.Component {
  state = {
    showSignaturePad: false,
    signature: "",
    SignatuerPadWidth: "",
    initialLoad: true,
  };

  onChange = () => {
    const signature = this.signaturePad
      .getTrimmedCanvas()
      .toDataURL("image/png");
    this.setState({ signature });
    this.props.onChange(signature);
  };

  componentDidMount() {
    this.updateWidthSize();
    const { signature, showSignaturePad } = this.props;
    this.setState({
      signature,
      showSignaturePad,
    });
    window.addEventListener(
      "resize",
      function () {
        this.updateWidthSize();
      }.bind(this),
      true
    );
  }
  updateWidthSize() {
    var width = window.innerWidth;
    if (width < 991) {
      width = width * 0.6;
      this.setState({ SignatuerPadWidth: width });
    } else {
      width = width * 0.45;
      this.setState({ SignatuerPadWidth: width });
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!isEqual(nextProps, this.props) && !isNull(nextProps)) {
      const { signature, isEditMode, showSignaturePad } = nextProps;
      if (this.state.signature != signature) {
        this.setState({
          signature,
        });
      }
      if (
        isEditMode == true &&
        showSignaturePad == false &&
        this.state.initialLoad
      ) {
        this.setState({
          showSignaturePad: showSignaturePad,
          initialLoad: false,
        });
      }
    }
  }

  render() {
    const { showSignaturePad, signature, SignatuerPadWidth } = this.state;
    if (showSignaturePad) {
      return (
        <div
        // className="signature-container"
        >
          <div
            style={{
              width: SignatuerPadWidth,
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: "grey",
              borderRadius: 10,
            }}
          >
            <SignatureCanvas
              penColor="green"
              canvasProps={{
                width: SignatuerPadWidth * 0.99,
                height: 123,

                className: "sigCanvas",
              }}
              ref={(ref) => {
                this.signaturePad = ref;
              }}
              onEnd={this.onChange}
            />
          </div>
          <div>
            <button
              type="button"
              onClick={(event) => {
                this.signaturePad.clear();
                this.setState({ signature: "" });
                this.props.onChange("");
              }}
              className="button signature-clear"
              style={{
                marginBottom: 3,
                marginTop: 3,
                width: SignatuerPadWidth,
              }}
            >
              CLEAR
            </button>
            {!this.props.showSignaturePad && (
              <button
                type="button"
                onClick={(event) => {
                  this.setState({ showSignaturePad: false });
                }}
                className="button signature-close"
                style={{
                  marginBottom: 3,
                  marginTop: 3,
                  width: SignatuerPadWidth,
                }}
              >
                CLOSE
              </button>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className={"signature-view-container"}>
          <div className="signature-view">
            {signature != "" && signature != null && (
              <img className="signature-img" src={signature} alt="Signature" />
            )}
          </div>
          <div>
            <button
              type="button"
              onClick={(event) => {
                this.setState({ showSignaturePad: true });
              }}
              className="button signature-edit"
            >
              EDIT
            </button>
          </div>
        </div>
      );
    }
  }
}

export default SignaturePad;
