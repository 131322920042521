import Clients from "../../clients/clients";
import Staff from "../../staff/staff";
import SupportCoordinator from "../../support-cordinator/support-cordinator";
import WorkEntry from "../../work-entries/workEntries";
import Reports from "../../reports/billingReport";
import Dashboard from "../../dashboard/dashboard";
import Services from "../../services/services";

export default [
  {
    name: "DASHBOARD",
    url: "dashboard",
    component: Dashboard,
    isSideBarNavigation: true,
    entitlements: [],
    icon: "./images/calendar-grey.svg",
    selectedIcon: "./images/calendar-green.svg",
  },
  {
    name: "CLIENTS",
    url: "admin/clients",
    component: Clients,
    isSideBarNavigation: true,
    entitlements: [2001, 2002, 2003, 2004],
    icon: "./images/users.svg",
    selectedIcon: "./images/users-selected.svg",
  },
  {
    name: "SERVICES",
    url: "admin/services",
    component: Services,
    isSideBarNavigation: true,
    entitlements: [9000, 9001, 9002],
    icon: "./images/clipboard-grey.svg",
    selectedIcon: "./images/clipboard-green.svg",
  },
  {
    name: "STAFF",
    url: "admin/staff",
    component: Staff,
    isSideBarNavigation: true,
    entitlements: [1001, 1002, 1003, 1004, 1005, 1006],
    icon: "./images/book.svg",
    selectedIcon: "./images/book-selected.svg",
  },
  {
    name: "SUPPORTS COORDINATOR",
    url: "admin/support-coordinator",
    component: SupportCoordinator,
    isSideBarNavigation: true,
    authUsers: [1, 0],
    entitlements: [4001, 4002, 4003, 4004],
    icon: "./images/zap-not.svg",
    selectedIcon: "./images/zap.svg",
  },
  {
    name: "ENTITLEMENTS",
    url: "admin/entitlements/entitlement-groups",
    isSideBarNavigation: true,
    entitlements: [9000, 9001, 9002],
    icon: "./images/entitlements.svg",
    selectedIcon: "./images/entitlements-selected.svg",
  },
  {
    name: "WORK ENTRIES",
    url: "admin/work-entries",
    component: WorkEntry,
    isSideBarNavigation: true,
    entitlements: [5001, 5002, 5003, 5004, 5005, 5006, 5007, 5008, 5009],
    icon: "./images/file-plus.svg",
    selectedIcon: "./images/file-plus-selected.svg",
  },
  {
    name: "REPORTS",
    url: "admin/reports",
    component: Reports,
    isSideBarNavigation: true,
    entitlements: [6001, 6002, 6003, 6004, 6005, 6006, 6007, 6008],
    icon: "./images/reports.svg",
    selectedIcon: "./images/reports-selected.svg",
  },
  {
    name: "VISITS",
    url: "staff/visits",
    component: WorkEntry,
    isSideBarNavigation: true,
    icon: "./images/file-plus.svg",
    selectedIcon: "./images/file-plus-selected.svg",
  },
  {
    name: "REPORTS",
    url: "staff/reports",
    component: Reports,
    isSideBarNavigation: true,
    authUsers: [2],
    icon: "./images/reports.svg",
    selectedIcon: "./images/reports.svg",
  },
];
