import React from "react";
import { Modal } from "../shared/modal/Modal";
import { InputLabel, InputRelative } from "../organizations/styles";
import { InputForm, SelectForm } from "../shared/form-fields/Inputs";
import { FormProvider, useForm } from "react-hook-form";
import { initialCreateEntitlement } from "./handleEntForm";
import { createEntitlement, updateEntitlement } from "../../api/entitlements";
import { createNotification } from "../../util/notificationManager";
import { ALERT_TYPES } from "../../constants/appConstants";
import { Select } from "./styles";
import { isNumber } from "lodash";
import { useEffect } from "react";
import { useState } from "react";

export const CreateEntitlement = ({ open, close, getData, edit, data }) => {
  const methods = useForm({
    defaultValues: edit
      ? {
          entitlementGroupId: data.entitlementGroupId,
          name: data.name,
          userType: data.userType,
        }
      : initialCreateEntitlement,
  });

  const [userType, setUserType] = useState(1);

  useEffect(() => {
    if (edit) {
      setUserType(data.userType);
    }
  });

  const onSubmit = (values) => {
    values = { ...values, userType: userType };
    if (edit) {
      updateEntitlement(values)
        .then((res) => {
          if (res.status === 200) {
            getData(1);
            createNotification(
              ALERT_TYPES.SUCCESS,
              "Entitlement has been updated",
            );
            close();
          }
        })
        .catch((e) => {
          createNotification(ALERT_TYPES.ERROR, e);
        });
    } else {
      createEntitlement(values)
        .then((res) => {
          if (res.status === 200) {
            getData(1);
            createNotification(
              ALERT_TYPES.SUCCESS,
              "New Entitlement has been created",
            );
            close();
          }
        })
        .catch((e) => {
          createNotification(ALERT_TYPES.ERROR, e);
        });
    }
  };

  return (
    <Modal
      open={open}
      close={close}
      title={`${edit ? "Update" : "New"} Entitlement Group`}
      iconTitle="./images/entitlements-selected.svg"
      width="30vw"
      height="max-content"
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          style={{ padding: "0 2em" }}
        >
          <InputRelative style={{ paddingBottom: 20 }}>
            <InputLabel>User Type</InputLabel>
            {/*<SelectForm*/}
            {/*  name="userType"*/}
            {/*  required*/}
            {/*  error={methods.formState.errors?.userType}*/}
            {/*  options={()=>{*/}
            {/*    return [{}]*/}
            {/*  }}*/}
            {/*/>*/}
            <Select
              name="userType"
              value={userType}
              onChange={(event) => {
                setUserType(parseInt(event.target.value));
              }}
              disabled={edit}
            >
              <option value={0}>Super Admin</option>
              <option value={1}>Admin</option>
            </Select>
          </InputRelative>

          <InputRelative>
            <InputLabel>Group Name</InputLabel>
            <InputForm
              name="name"
              required
              error={methods.formState.errors?.name}
            />
          </InputRelative>

          <div className="row justify-content-center mt-5">
            <button className="button submit col-4 mr-4" type="submit">
              <span className="text">SAVE</span>
            </button>

            <button className="button basic col-4" onClick={close}>
              CANCEL
            </button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};
