import React, { useState } from "react";
import { Input, TitleOrg } from "../organizations/styles";
import { CreateEntitlement } from "./CreateEntitlement";
import { useEffect } from "react";

export const Entitlements = ({ getData, searchParam, setSearchParam }) => {
  const [createEntitlement, setCreateEntitlement] = useState(false);

  console.log("searchParam", searchParam);
  console.log("setSearchParam", setSearchParam);

  const [searchText, setSearchText] = useState("");

  const onSearch = (event) => {
    const val = event.target.value;
    console.log("onSearch", val);
    if (val.length >= 3 || val.length === 0) {
      setSearchParam(val);
    }
  };

  const onPressEnter = (event) => {
    if (event.keyCode === 13) {
      const val = event.target.value;
      console.log("onPressEnter", val);
      setSearchParam(val);
    }
  };

  useEffect(() => {
    setSearchText(searchParam);
  });

  return (
    <>
      <TitleOrg style={{ marginBottom: "3em" }}>Entitlements</TitleOrg>

      <div className="row justify-content-between ">
        <div className="col-lg-5 col-sm-10 input-box" style={{ padding: 0 }}>
          <Input
            style={{ left: 0 }}
            type="text"
            className="box text-box search-icon"
            placeholder="Entitlement Group"
            value={searchText}
            onChange={onSearch}
            onKeyDown={onPressEnter}
          />
        </div>

        <button
          className="button submit col-2"
          onClick={() => setCreateEntitlement(true)}
        >
          CREATE NEW
        </button>
      </div>

      {createEntitlement && (
        <CreateEntitlement
          open={createEntitlement}
          close={() => setCreateEntitlement(false)}
          getData={getData}
        />
      )}
    </>
  );
};
