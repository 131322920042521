export const ENTITLEMENTS = {
  XAdmin: 0,

  UserModule: 1000,
  ViewAllUsers: 1001,
  CreateNewUsers: 1002,
  UpdateUsers: 1003,
  ViewUserDetails: 1004,
  ResetUserPasswords: 1005,
  ViewUserSSN: 1006,

  ClientModule: 2000,
  ViewAllClients: 2001,
  CreateNewClients: 2002,
  UpdateClients: 2003,
  ViewClientDetails: 2004,

  ClientServicesModule: 2100,
  ViewAllClientServices: 2101,
  CreateNewClientService: 2102,
  UpdateClientService: 2103,

  ClientServiceAllocation: 2200,
  ViewClientServiceAllocation: 2201,
  UpdateClientServiceAllocation: 2202,

  ServicesModule: 3000,

  SupportsCoordinatorsModule: 4000,
  ViewAllSupportsCoordinators: 4001,
  CreateNewSupportsCoordinators: 4002,
  UpdateSupportsCoordinators: 4003,
  ViewSupportsCoordinatorDetails: 4004,

  WorkEntriesModule: 5000,
  ViewCompletedWorkEntries: 5001,
  ViewInProgressWorkEntries: 5002,
  ViewAbandonedWorkEntries: 5003,
  OverrideWorkEntryValidations: 5004,
  ApproveWorkEntries: 5005,
  CreateNewWorkEntries: 5006,
  UpdateWorkEntries: 5007,
  ViewWorkEntryDetails: 5008,
  ViewWorkEntryHistory: 5009,

  ReportsModule: 6000,
  DownloadGeneratedReports: 6001,
  GenerateNotesReport: 6002,
  GenerateStaffBillingReport: 6003,
  GeneratePromiseBillingReport: 6004,
  GenerateAllContactsReport: 6005,
  GenerateSupportsCoordinatorReport: 6006,
  GenerateStaffSummaryReport: 6007,
  GenerateStaffAllocationSummaryReport: 6008,

  VisitsModule: 7000,

  StaffReportsModule: 8000,

  OrganizationsModule: 9000
};
