import { styled } from "styled-components";

export const Select = styled.select`
  height: 39px;
  color: #b0b9c9;
  font-family: "PT Sans";
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 19px;
  text-align-last: center;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

export const SubTitle = styled.div`
  color: #333333;
  font-family: Muli;
  font-size: 14px;
  text-align: ${({ center }) => center && "center"};
`;

export const ContOptions = styled.div`
  border-radius: 9px;
  background-color: #f6f8fa;
  padding: 1em;
  flex: 1;
  width: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  word-wrap: break-word;
  /* max-height: 10em; */
`;

export const ContOneOption = styled.div`
  padding: 10px;
  outline: ${({ selected }) => selected && "1px solid #35CC62"};
  margin-bottom: 0.5em;
  border-radius: 8px;
  cursor: pointer;
`;

export const ContStaff = styled.div`
  display: flex;
  flex: 1;
  max-height: 80%;
  gap: 3em;
  justify-content: center;
`;

export const ContArrows = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2em;
`;
