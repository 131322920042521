import React, { useState, useEffect } from "react";
import DataGrid from "../../shared/data-grid/data-grid";
import Table from "../../shared/data-grid/table";
import { getAuthData } from "../../../util/auth";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "./staffReports.scss";
import { generateReport, getStaffPayReport } from "../../../api/report";
import {
  DATEFILTEROPTIONS,
  REPORT_TYPES,
} from "../../../constants/appConstants";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { isMobileDevice } from "../../../util/deviceType";
import StaffPayCard from "../../staff/staffPayCard";
import { map } from "lodash";
import Pagination from "../../shared/data-grid/pagination/pagination";
import { styled } from "@mui/material/styles";
import MuiDateRangePickerDay from "@mui/lab/DateRangePickerDay";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiButtonBase: {
      root: {
        MuiPickersDay: {
          root: {
            MuiDateRangePickerDay: {
              styleOverrides: { day: { background: "red" } },
            },
          },
        },
      },
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: { borderRadius: 20 },
      },
    },
  },
});

const DateRangePickerDay = styled(MuiDateRangePickerDay)(
  ({
    theme,
    isHighlighting,
    isStartOfHighlighting,
    isEndOfHighlighting,
    root,
  }) => ({
    ...(isHighlighting && {
      borderRadius: 0,
      backgroundColor: "#FAFBFC",
      color: theme.palette.common.white,
      " &:focus": {
        backgroundColor: "#B0B9C9",
      },
    }),
    ...(isStartOfHighlighting && {
      borderTopLeftRadius: "50%",

      borderBottomLeftRadius: "50%",

      "& .Mui-selected": {
        backgroundColor: "#B0B9C9 !important",
        "&:hover, &:focus": {
          backgroundColor: "#B0B9C9",
        },
      },
    }),
    ...(isEndOfHighlighting && {
      borderTopRightRadius: "50%",

      borderBottomRightRadius: "50%",
      "& .Mui-selected": {
        backgroundColor: "#B0B9C9 !important",
        "&:hover, &:focus": {
          backgroundColor: "#B0B9C9",
        },
      },
    }),
    "& .MuiPickersDay-today": {
      backgroundColor: "#35CC62 ",
      border: "none !important",
      color: "#FAFAFA",
      "&:hover, &:focus": {
        backgroundColor: "#B0B9C9",
      },
    },
  }),
);

const renderWeekPickerDay = (date, dateRangePickerDayProps) => {
  return <DateRangePickerDay {...dateRangePickerDayProps} />;
};

function StaffReports() {
  const [value, setValue] = useState([null, null]);
  const [state, setState] = useState({
    value: [null, null],
    reportType: REPORT_TYPES.STAFF_PAY,
    reviewStatus: "all",
    pageNumber: 0,
    pageSize: 10,
    dateRangeOption: DATEFILTEROPTIONS.CREATED,
    fromDate: new Date(new Date().setDate(new Date().getDate() - 7)).setHours(
      0,
      0,
      0,
      0,
    ),
    fromDateDisplay: moment(
      new Date(new Date().setDate(new Date().getDate() - 7)),
    ).format("MM/DD/YYYY"),
    toDate: new Date().setHours(0, 0, 0, 0),
    toDateDisplay: moment(new Date()).format("MM/DD/YYYY"),
    clientId: -1,
    serviceId: -1,
    staffId: "",
    showCalender: false,
    showChart: false,
    data: [],
    recordCount: 0,
    stats: null,
    buttonBackground: "rgb(183, 182, 182)",
  });

  const onReportSelect = (e) => {
    const reportType = e.target.value;

    setState({ ...state, reportType: reportType });
  };

  useEffect(() => {
    if (JSON.parse(getAuthData()).userId != null) {
      setState((obj) => {
        return { ...obj, staffId: JSON.parse(getAuthData()).userId };
      });
    }
  }, []);
  useEffect(() => {
    onGenerateReport(0, 10);
  }, [state.staffId]);
  function onGenerateReport(offset, limit) {
    let fromDate = moment.utc(state.fromDate).format("YYYY-MM-DD HH:mm:ss");
    let toDate = moment.utc(state.toDate).format("YYYY-MM-DD HH:mm:ss");

    if (state.fromDate !== null && state.toDate !== null) {
      getStaffPayReport(
        state.reportType,
        state.reviewStatus,
        fromDate,
        toDate,
        state.dateRangeOption,
        state.clientId,
        state.staffId,
        state.serviceId,
        offset,
        limit,
      )
        .then((res) => {
          if (res.data.data !== null) {
            setState((values) => {
              return {
                ...values,
                data: res.data.data,
                stats: res.data.stats,
                recordCount: res.recordCount,
              };
            });
          } else {
            setState({ ...state, data: [] });
          }
        })
        .catch((e) => {
          console.log("staff_Pay error", e);
        });
    }
  }

  function onPageChange(pageNumber) {
    let limit = parseInt(state.pageSize);
    let offset = limit * pageNumber;
    onGenerateReport(offset, limit);
    setState((values) => {
      return { ...values, pageNumber: pageNumber };
    });
  }

  function onPageSizeChange(pageSize) {
    setState({
      ...state,
      pageNumber: 0,
      pageSize: pageSize,
    });
    onGenerateReport(0, parseInt(pageSize));
  }

  return (
    <div className="table-layout">
      <div className="row report-type-selector">
        <div className="col-lg-2 col-md-2 col-sm-3">
          <div className="page-title">Reports</div>
        </div>
      </div>

      <div className="body-container" style={{ margin: "20px 0" }}>
        <div className="list-container">
          <div>
            <div className="list-header">Staff Pay Summary</div>
          </div>
        </div>

        <hr className="body-line" />
        <div className="data-grid-container">
          <div>
            {!isMobileDevice() && (
              <div style={{ display: "flex" }}>
                <ThemeProvider theme={theme}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateRangePicker
                      value={value}
                      maxDate={new Date()}
                      renderDay={renderWeekPickerDay}
                      onChange={(arr) => {
                        setValue(() => {
                          const fromDate = arr[0] === null ? null : arr[0];
                          const fromDateDisplay =
                            fromDate === null
                              ? ""
                              : moment(arr[0]).format("MM/DD/YYYY");
                          const toDate = arr[1] === null ? null : arr[1];
                          const toDateDisplay =
                            toDate === null
                              ? ""
                              : moment(arr[1]).format("MM/DD/YYYY");
                          setState((state) => {
                            let obj = state;
                            let background = "rgb(183, 182, 182)";
                            if (fromDate !== null && toDate !== null) {
                              background = "#35cc62";
                            }
                            return {
                              ...obj,
                              value: arr,
                              fromDate: fromDate,
                              fromDateDisplay: fromDateDisplay,
                              toDate: toDate,
                              toDateDisplay: toDateDisplay,
                              buttonBackground: background,
                            };
                          });

                          return arr;
                        });
                      }}
                      allowSameDateSelection={false}
                      renderInput={(startProps, endProps) => (
                        <>
                          <div className=" label"> Date</div>

                          <div
                            className=" col-lg-12 col-sm-12 input-box text-left"
                            style={{
                              marginBottom: 10,
                              marginRight: "20px",
                              height: 48,
                              width: "100%",
                            }}
                            {...startProps.inputProps}
                          >
                            <input
                              type="input"
                              style={{ cursor: "default" }}
                              readOnly={true}
                              placeholder="02-01-2022 to 02-28-2022"
                              value={`${state.fromDateDisplay} ${
                                state.fromDateDisplay ? "-" : ""
                              }${state.toDateDisplay}`}
                              className=" box text-box calendar-icon"
                            />
                          </div>

                          {
                            <button
                              className=" col-lg-3   button submit"
                              style={{
                                height: 38,
                                marginTop: 5,
                                backgroundColor: state.buttonBackground,
                              }}
                              onClick={() => {
                                onGenerateReport(0, state.pageSize);
                              }}
                            >
                              VIEW
                            </button>
                          }
                        </>
                      )}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </div>
            )}

            {isMobileDevice() && (
              <div style={{ display: "flex" }}>
                <ThemeProvider theme={theme}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateRangePicker
                      value={value}
                      maxDate={new Date()}
                      showToolbar={false}
                      renderDay={renderWeekPickerDay}
                      onChange={(arr) =>
                        setValue(() => {
                          const fromDate = arr[0] === null ? null : arr[0];
                          const fromDateDisplay =
                            fromDate === null
                              ? ""
                              : moment(arr[0]).format("MM/DD/YYYY");
                          const toDate = arr[1] === null ? null : arr[1];
                          const toDateDisplay =
                            toDate === null
                              ? ""
                              : moment(arr[1]).format("MM/DD/YYYY");
                          setState((state) => {
                            let obj = state;
                            let background = "rgb(183, 182, 182)";
                            if (fromDate !== null && toDate !== null) {
                              background = "#35cc62";
                            }
                            return {
                              ...obj,
                              value: arr,
                              fromDate: fromDate,
                              fromDateDisplay: fromDateDisplay,
                              toDate: toDate,
                              toDateDisplay: toDateDisplay,
                              buttonBackground: background,
                            };
                          });
                          return arr;
                        })
                      }
                      allowSameDateSelection={false}
                      renderInput={(startProps, endProps) => (
                        <>
                          <div className=" label"> Date</div>

                          <div
                            className=" col-12 input-box text-left"
                            style={{
                              marginBottom: 10,
                              marginRight: "20px",
                              height: 48,
                              width: "100%",
                            }}
                            {...startProps.inputProps}
                          >
                            <input
                              type="input"
                              style={{ width: "80%" }}
                              readOnly={true}
                              placeholder="02-01-2022 to 02-28-2022"
                              value={`${state.fromDateDisplay} ${
                                state.fromDateDisplay ? "-" : ""
                              }${state.toDateDisplay}`}
                              className=" box text-box calendar-icon"
                            />
                          </div>
                        </>
                      )}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </div>
            )}
            {isMobileDevice() && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="  col-sm-3  button submit"
                  style={{
                    height: 38,
                    marginTop: 5,
                    width: "50%",
                    backgroundColor: state.buttonBackground,
                  }}
                  onClick={() => {
                    onGenerateReport(0, state.pageSize);
                  }}
                >
                  VIEW
                </button>
              </div>
            )}
          </div>
          {state.stats !== null && (
            <div style={{ margin: 10 }}>
              <div className=" pay-label">
                <p className="text">
                  {`Total to pay for this week: ${state.stats.totalNet.toFixed(
                    2,
                  )}`}
                </p>
              </div>
            </div>
          )}
          {!isMobileDevice() &&
            (state.data.length > 0 ? (
              <Table
                data={state.data}
                showPagination={true}
                pageNumber={state.pageNumber}
                pageSize={state.pageSize}
                onPageSizeChange={(pageSize) => onPageSizeChange(pageSize)}
                onPageNumberChange={(pageNumber) => onPageChange(pageNumber)}
                paginationLabel={"Staff pay per page"}
                total={state.recordCount}
              />
            ) : (
              <DataGrid
                data={[]}
                columns={[]}
                onRowClick={(rowInfo) => {}}
                paginationLabel={"Staff pay per page"}
                showPagination={false}
              />
            ))}
          {isMobileDevice() && state.data.length > 0 && (
            <div className="row" style={{ margin: 10 }}>
              {map(state.data, (data, index) => {
                return <StaffPayCard key={`staff-${index}`} data={data} />;
              })}
              <div className="row pagination pagination-align">
                <div className="col-12">
                  <Pagination
                    onPageSizeChange={onPageSizeChange}
                    label={"Staff pay per page"}
                    onPageNumberChange={onPageChange}
                    total={state.recordCount}
                    pageSize={state.pageSize}
                    pageNumber={state.pageNumber}
                  />
                </div>
              </div>
            </div>
          )}

          {isMobileDevice() && state.data.length === 0 && (
            <div className="row">
              <div className="col-12 text-center" style={{ margin: 10 }}>
                <span>No rows found </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(StaffReports);
