import * as React from "react";
import { Field, Form, reduxForm } from "redux-form";
import {
  inputField,
  renderDatePicker,
  selectField,
  textField
} from "../../shared/form-fields/formFields";
import { withRouter } from "react-router-dom";
import {
  validateClientServiceCode,
  validateClientServiceEndDate,
  validateClientServiceName,
  validateClientServiceStartDate,
  validateClientServiceUnitLength,
  validateClientServiceUnitRate,
  validateClientServiceUnitsAuthorized,
  validateOverlapOption
} from "../create-client/create-client-form/clientValidator";
import Files from "../../staff/create-staff/create-staff-form/files";
import moment from "moment";
import { OVERLAP_OPTION } from "../../../constants/appConstants";

class ClientServiceInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <Form style={{ width: "100%" }} onSubmit={handleSubmit} noValidate>
        <div style={{ margin: 30, marginTop: 0 }}>
          <div className="row">
            <div className="col-6 input-box text-left">
              <div className="label">Service Name</div>
              <Field
                name="serviceName"
                component={inputField}
                type="text"
                placeholder={"Service Name"}
                required={true}
                validate={validateClientServiceName}
              />
            </div>
            <div className="col-6 input-box text-left">
              <div className="label">Service Code</div>
              <Field
                name="serviceCode"
                component={inputField}
                type="text"
                placeholder={"Service Code"}
                required={true}
                validate={validateClientServiceCode}
              />
            </div>
            <div className="col-12 input-box text-left">
              <div className="label">Description</div>
              <Field
                name="serviceDescription"
                component={textField}
                type="text"
                placeholder={"Description"}
                required={true}
              />
            </div>
            <div className="col-6 input-box text-left">
              <div className="label">Unit Rate</div>
              <Field
                name="serviceUnitRate"
                component={inputField}
                type="number"
                placeholder={"Unit Rate"}
                required={true}
                minVal={0}
                validate={validateClientServiceUnitRate}
              />
            </div>
            <div className="col-6 input-box text-left">
              <div className="label">Unit Length (hrs.)</div>
              <Field
                name="serviceUnitLength"
                component={inputField}
                type="number"
                placeholder={"Unit Length"}
                required={true}
                minVal={0}
                validate={validateClientServiceUnitLength}
              />
            </div>

            <div className="col-6 input-box text-left my-auto">
              <input
                type="checkbox"
                name="degreeRequired"
                value="degreeRequired"
                checked={this.props.isDegreeRequired}
                onChange={(event) => {
                  this.props.onDegreeRequired(event.target.checked);
                }}
                style={{ marginRight: 5 }}
              />
              <span className="label">Degree Required</span>
            </div>
          </div>

          <div className="row" style={{paddingTop:20}}>
            <div className="col-6 input-box text-left my-auto">
              <span className="label" style={{marginBottom:10}}>Overlappable Service</span>
              <Field
                name="overlapOption"
                component={selectField}
                placeholder={"Select Overlappable Service"}
                required={true}
                onChange={this.props.onOverlapable}
                options={OVERLAP_OPTION}
                validate={validateOverlapOption}
              />
            </div>

          </div>

          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
              <div className="row">
                <div className="col-6">
                  <button type="submit" className="button submit">
                    {this.props.initialValues == null ? "SAVE" : "UPDATE"}
                  </button>
                </div>
                <div className="col-6">
                  <button
                    className="button basic"
                    onClick={(event) => {
                      this.props.onCloseModal();
                    }}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

const serviceForm = reduxForm({
  form: "addService",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(ClientServiceInfo);

export default withRouter(serviceForm);
// export default ClientServiceInfo;
