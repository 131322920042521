import { keyframes, styled } from "styled-components";
import { BrowserRouter as Router, NavLink } from "react-router-dom";

export const scaleUp = keyframes`
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
`;

export const TitleOrg = styled.div`
  color: #27395a;
  font-size: 28px;
  font-weight: 600;
  font-family: Muli;
`;

export const FormCard = styled.div`
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.08);
  margin-top: 2em;
`;


export const Titles = styled.div`
  color: #333333;
  font-family: Muli;
  font-size: 22px;
  font-weight: 600;
`;

export const Input = styled.input`
  height: 48px;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(61, 74, 94, 0.06);
  border: 1px solid #e9e9e9;
  color: #27395a;
  font-size: 14px;
  padding: 8px 10px;
  width: -webkit-fill-available;
  font-family: Muli;
`;

export const OrgStatus = styled.div`
  margin-top: 2em;
  background-color: ${({ color }) => color && color};
  border-radius: 15px;
  color: #ffffff;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  width: max-content;
  height: 30px;
  padding: 0 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Labels = styled.div`
  color: ${({ content }) => (content ? "#B0B9C9" : "#333333")};
  font-family: Muli;
  font-size: 14px;
  margin-top: ${({ content }) => content && "1em"};
`;


export const Headers = styled.div`
  color: #27395a;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
`;

export const InputLabel = styled.p`
  color: #263859;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
`;

export const RequiredField = styled.p`
  color: #ff584e;
  font-size: 12px;
  letter-spacing: 0.07px;
  font-family: Muli;
  margin-top: 0.3em;
  position: absolute;
  right: 0;
`;
export const ContTable = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: ${({ header }) => (header ? "2em 1em" : "0.6em 1em")};
  background-color: ${({ gray }) => gray && "#FAFAFA"};
  align-items: center;
`;

export const Details = styled.div`
  color: #333333;
  font-family: Muli;
  font-size: 14px;
`;

export const ContActions = styled.div`
  display: flex;
  align-items: center;
  gap: 3em;
`;

export const ContStatus = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7em;
`;

export const TextArea = styled.textarea`
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(61, 74, 94, 0.06);
  border: ${({ error }) => (error ? "1px solid #ff584e" : "1px solid #e9e9e9")};
  color: #27395a;
  font-size: 14px;
  padding: 8px 10px;
  width: -webkit-fill-available;
  min-height: 10em;
  font-family: Muli;

  &::placeholder {
    color: #b0b9c9;
    font-size: 14px;
    font-family: Muli;
  }
`;

export const InputImage = styled.input`
  opacity: 0;
  position: absolute;
  top: 34px;
  left: 8px;
  width: inherit;
  height: inherit;
  cursor: pointer;
`;

export const UploadLogo = styled.div`
  height: 138px;
  width: 138px;
  background-color: #f9fafb;
  color: #b0b9c9;
  font-size: 14px;
  font-weight: 600;
  font-family: Muli;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.p`
  color: #b0b9c9;
  font-size: 10px;
  font-weight: 600;
  font-family: Muli;
`;

export const ContLogo = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  align-items: center;
  gap: 0.5em;
`;

export const ContOrgDetails = styled.div`
  display: flex;
  gap: 11em;
  margin-top: 1em;
`;

export const MenuOptions = styled(NavLink)`
  color: #333333;
  font-family: Muli;
  font-size: 14px;
`;

export const ContMenu = styled.div`
  display: flex;
  gap: 3em;
`;

export const Back = styled.div`
  color: #263859;
  font-size: 14px;
  font-weight: 600;
  font-family: Muli;
  margin-bottom: 2.5em;
  display: flex;
  gap: 0.5em;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`;

export const Select = styled.select`
  height: 48px;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(61, 74, 94, 0.06);
  border: 1px solid #e9e9e9;
  color: #27395a;
  font-size: 14px;
  padding: 8px 10px;
  width: -webkit-fill-available;
  font-family: Muli;
`;

export const BackgroundModal = styled.div`
  background: rgba(29, 29, 46, 0.43);
  position: fixed;
  top: 0.1px;
  left: 0.1px;
  right: 0.1px;
  bottom: 0.1px;
  justify-content: center;
  align-items: center;
  z-index: 100;
  display: ${({ open }) => (open ? "flex" : "none")};
`;

export const ModalCard = styled.div`
  border-radius: 7px;
  background-color: #ffffff;
  width: ${({ alert }) => (alert ? "460px" : "750px")};
  height: auto;
  position: relative;
  animation: ${scaleUp} 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`;

export const ContSection = styled.div`
  display: flex;
  gap: 1em;
  justify-content: space-between;
`;

export const ContButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1em;
  margin-top: 3em;
  width: 100%;
`;

export const ContConfirmation = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ContHeaderAlert = styled.div`
  display: flex;
  border-bottom: 1px solid #e9e9e9;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const ContTitle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1em;
`;

export const ContFlex = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  align-items: baseline;
`;

export const ContInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3em 3em;
`;

export const ContGridLocation = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3em 2em;
  margin-top: 3em;
`;

export const Alert = styled.div`
  background-color: #ff293a;
  color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  height: 52px;
  border-radius: 3px;
  padding: 0 1em;
  width: max-content;
  align-items: center;
  gap: 1em;
  margin: 0 0 2em 0;
  display: ${({ show }) => (show ? "flex" : "none")};
`;

export const InputRelative = styled.div`
  position: relative;
  width: 100%;
`;

export const ContEntToggle = styled.div`
  border-radius: 15px;
  background-color: #fafafa;
  padding: 1em 2em;
  margin-bottom: 1em;
`;

export const ContToggle = styled.div`
  margin-top: 2em;
  gap: 1.5em;
  display: flex;
  flex-direction: column;
  margin-left: 2.5em;
`;