import React, { useEffect, useState } from "react";
import { Details } from "../../styles";
import { CreateUser } from "./CreateUser";
import { useParams } from "react-router-dom";
import PaginationComponent from "../../../shared/pagination/pagination";
import { useOrganizations } from "../../hooks/useOrganizations";
import { Modal } from "../../../shared/modal/Modal";
import { getUserById } from "../../../../api/user";
import { ImpersonationWarning } from "./ImpersonationWarning";

export const Users = () => {
  return null
};
