import React from "react";
import { get, map, startCase } from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getClientServiceByClientId } from "../../../api/clientService";
import { getClientById } from "../../../api/client";
import { isMobileDevice } from "../../../util/deviceType";
import MapContainer from "../../shared/map-container/mapContainer";
import {
  setVisitDetails,
  setVisitStatus
} from "../../../redux/workEntries/workEntryAction";
import StartVisit from "./start-visit";
import { startVisit } from "../../../api/visits";
import { ALERT_TYPES, VISIT_STATUS } from "../../../constants/appConstants";
import { getWorkEntryById } from "../../../api/workEntry";
import { createNotification } from "../../../util/notificationManager";
import { getTimeZone } from "../../../util/util";
import ShowMoreText from "react-show-more-text";

class VisitInit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      selectedService: null,
      selectedClient: null,
      viewMap: false,
      openModal: false
    };
  }

  componentDidMount() {
    const selectedClient = get(
      this.props.location.state,
      "selectedClient",
      null
    );
    if (selectedClient != null) {
      getClientById(selectedClient).then((res) => {
        if (res) {
          this.setState({ selectedClient: res });
        }
      });
      getClientServiceByClientId(selectedClient).then((res) => {
        if (res) {
          this.setState({
            services: res,
            selectedService: res.length !== 0 ? res[0] : null
          });
        }
      });
    } else {
      this.props.history.goBack();
    }
  }

  onStartVisit = (notes) => {
    let visit = {
      clientId: this.state.selectedClient.clientId,
      serviceRequestId: this.state.selectedService.serviceRequestId,
      serviceStartTime: new Date(new Date().setSeconds(0, 0))
        .toISOString()
        .slice(0, 19)
        .replace("T", " "),
      serviceEndTime: new Date(new Date().setSeconds(0, 0))
        .toISOString()
        .slice(0, 19)
        .replace("T", " "),
      notes: notes,
      timeZone: getTimeZone()
    };

    startVisit(visit)
      .then((res) => {
        getWorkEntryById(res.workEntryId).then((we) => {
          this.props.dispatch(setVisitDetails(we));
          this.props.dispatch(
            setVisitStatus(VISIT_STATUS.IN_PROGRESS, new Date())
          );
          this.props.history.push("/staff/visit-progress");
        });
      })
      .catch((err) => {
        createNotification(ALERT_TYPES.ERROR, err.message);
      });
  };

  onServiceSelect = (service) => {
    this.setState({
      selectedService: service
    });
  };

  render() {
    return (
      <div className="col-lg-10 col-md-10 col-sm-10 offset-lg-1 offset-lg-1 offset-lg-1">
        <div className="row">
          <div
            className="row col-lg-2 col-md-2 col-sm-4"
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <img src="./images/chevron-left-green.svg" alt="" />
            <span
              style={{
                fontFamily: "Muli",
                color: "#333333",
                fontSize: 15,
                padding: 10
              }}
            >
              Back
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="client-details">Start Visit</div>
          </div>
        </div>

        <div className="row col-12 offset-0" style={{ marginTop: 20 }}>
          <div className="page-content" style={{ width: "100%" }}>
            <img className="banner" src="./images/banner-blue.png" alt="" />
            {this.state.selectedClient && (
              <div
                className="col-lg-12 col-md-12 col-sm-12"
                style={{ padding: 20 }}
              >
                <div className="client-details text-center" style={{ fontSize: 21 }}>
                  {startCase(
                    `${get(this.state.selectedClient, "firstName")} ${get(
                      this.state.selectedClient,
                      "middleName"
                    )} ${get(this.state.selectedClient, "lastName")}`
                  )}
                </div>
                <div>
                  <span className="label">Goals & Outcomes : </span>
                  <ShowMoreText
                    /* Default options */
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="content-css"
                    anchorClass="show-more-less-clickable"
                    // onClick={this.executeOnClick}
                    expanded={false}
                    // width={280}
                    truncatedEndingComponent={"... "}
                  >
                    <span className="text">
                  {get(this.state.selectedClient, "goalsOutcomes")}
                  </span>
                  </ShowMoreText>

                </div>

              </div>
            )}
            {/*<div className='row'>*/}
            {/*    <div className='col-lg-4 col-md-4 col-sm-6 text-center'>*/}
            {/*        <img src="./images/map-pin.svg" alt="" style={{marginRight: 10, padding: 10}}/>*/}
            {/*        <span className='label' style={{padding: 10}}>Address</span>*/}
            {/*        <div className='value' style={{*/}
            {/*            padding: 10,*/}
            {/*            paddingBottom: 0,*/}
            {/*            overflow: 'hidden',*/}
            {/*            textOverflow: 'ellipsis'*/}
            {/*        }}>{`${get(this.state.selectedClient, "billingStreet", "")}, ${get(this.state.selectedClient, "billingCity", "")}, ${get(this.state.selectedClient, "billingState", "")}`}</div>*/}
            {/*        <span style={{*/}
            {/*            fontSize: 12,*/}
            {/*            color: "#35cc62",*/}
            {/*            textDecoration: "underline",*/}
            {/*            cursor: "pointer"*/}
            {/*        }}*/}
            {/*              onClick={() => {*/}
            {/*                  this.setState({viewMap: !this.state.viewMap})*/}
            {/*              }}>View Client Address in Map</span>*/}
            {/*    </div>*/}
            {/*    <div className='col-lg-4 col-md-4 col-sm-6 text-center'>*/}
            {/*        <img src="./images/phone.svg" alt="" style={{marginRight: 10, padding: 10}}/>*/}
            {/*        <span className='label' style={{padding: 10}}>Client Contact</span>*/}
            {/*        <div className='value'*/}
            {/*             style={{padding: 10}}>{get(this.state.selectedClient, "phone", "-")}</div>*/}
            {/*    </div>*/}
            {/*    <div className='col-lg-4 col-md-4 col-sm-6 text-center'>*/}
            {/*        <img src="./images/bookmark.svg" alt="" style={{marginRight: 10, padding: 10}}/>*/}
            {/*        <span className='label' style={{padding: 10}}>Medicaid ID</span>*/}
            {/*        <div className='value'*/}
            {/*             style={{padding: 10}}>{get(this.state.selectedClient, "mci", "-")}</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
          </div>

          {this.state.viewMap && (
            <div className="page-content" style={{ width: "100%" }}>
              <div className="row" style={{ marginBottom: 10 }}>
                <div className="col-1 offset-11">
                  <span
                    style={{
                      fontSize: 12,
                      color: "#5B6576",
                      textDecoration: "underline",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      this.setState({ viewMap: !this.state.viewMap });
                    }}
                  >
                    Hide Map
                  </span>
                </div>
              </div>
              <div className="row" style={{ marginBottom: 10 }}>
                <div
                  className="col-12"
                  style={{ height: isMobileDevice() ? "100vw" : "50vw" }}
                >
                  <MapContainer
                    address={`${get(
                      this.state.selectedClient,
                      "billingStreet",
                      ""
                    )} ${get(
                      this.state.selectedClient,
                      "billingCity",
                      ""
                    )}, ${get(
                      this.state.selectedClient,
                      "billingState",
                      ""
                    )} ${get(this.state.selectedClient, "billingCountry", "")}`}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="page-content" style={{ width: "100%" }}>
            <div className="row" style={{ marginBottom: 20 }}>
              <div className="col-12">
                <span className="label">Services Associated:</span>
              </div>
            </div>
            <div className="row">
              {map(this.state.services, (service, index) => {
                return (
                  <div key={index} className="col-12" style={{ margin: 10 }}>
                    <input
                      type="radio"
                      value={service.serviceRequestId}
                      name={"service"}
                      onChange={() => {
                        this.onServiceSelect(service);
                      }}
                      checked={
                        this.state.selectedService
                          ? service.serviceRequestId ===
                          this.state.selectedService.serviceRequestId
                          : false
                      }
                    />
                    <span style={{ marginLeft: 10 }}>
                      {service.serviceName}
                    </span>
                  </div>
                );
              })}
              {this.state.services.length === 0 && (
                <div className="col-12 text-center">
                  <span style={{ marginLeft: 10 }}>
                    No Service associated to this client
                  </span>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 offset-lg-4 offset-md-4 offset-sm-3">
                <button
                  className="button submit"
                  onClick={() => {
                    this.setState({ openModal: true });
                  }}
                  style={
                    this.state.selectedService === null
                      ? { backgroundColor: "#B7B6B6" }
                      : null
                  }
                  disabled={this.state.selectedService === null}
                >
                  <span className="text">START VISIT</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {this.state.openModal && (
          <StartVisit
            show={this.state.openModal}
            onClose={() => {
              this.setState({ openModal: false });
            }}
            client={this.state.selectedClient}
            service={this.state.selectedService}
            handleSubmit={this.onStartVisit}
            onCloseModal={() => {
              this.setState({ openModal: false });
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps)(VisitInit));
