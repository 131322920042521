import * as React from "react";
import "./header.scss";
import { getAuth, getToken, isImpersonationInProgress, setAuthData } from "../../../util/auth";
import { isNull, isUndefined } from "lodash";
import * as classNames from "classnames";
import { withRouter } from "react-router-dom";
import onClickOutside from "react-onclickoutside";
import { USER_TYPES } from "../../../constants/user";

class Header extends React.Component {

  render() {
    const token = getToken();
    const isAuthenticated = !isNull(token) && !isUndefined(token);
    let isImpersonation = isImpersonationInProgress();
    return (
      <div>
        <nav
          className="navbar navbar-expand navbar-white navbar-light main-nav"
          style={
            !isAuthenticated
              ? { display: "none" }
              : { position: "fixed", top: 0, right: 0, left: 0 }
          }
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <img src="./images/logo.svg" alt="" />
              <a className="text-center">
                <span className="navigation-title title">HCBS</span>
              </a>
            </li>
          </ul>
          <ul className="navbar-nav side-navigation-toggle">
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="pushmenu"
                href="#"
                role="button"
              >
                <img src={"./images/menu-green.svg"} alt="" style={{}} />
              </a>
            </li>
          </ul>
          {!isImpersonation && <Profile {...this.props} />}
        </nav>

      </div>
    );
  }
}

export default withRouter(Header);

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    let userData = getAuth();
    let username = "";
    let initials = "";
    if (userData != null) {
      username = userData.firstName;
      // username = userData.firstName + " " + userData.lastName;
      initials = (
        userData.firstName.charAt(0) + userData.lastName.charAt(0)
      ).toUpperCase();
    }
    this.state = {
      showDropDown: false,
      username: username,
      initials: initials,
      onOrganizations: this.props.location.pathname.includes(
        "/admin/organizations"
      )
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        onOrganizations: this.props.location.pathname.includes(
          "/admin/organizations"
        )
      });
    }
  }

  onSystemAdminClick = () => {
    this.props.history.push(
      this.state.onOrganizations ? "/admin/clients" : "/admin/organizations"
    );

    this.onProfileClick();
  };

  logOut = () => {
    setAuthData(null);
    this.props.history.push("login");
  };

  onProfileClick = () => {
    this.setState({
      showDropDown: !this.state.showDropDown
    });
  };

  onProfileSettingsClick = () => {
    this.props.history.push("/admin/profile");

    this.onProfileClick();
  };

  onFAQClick = () => {
    this.props.history.push("/admin/faq");

    this.onProfileClick();
  };

  handleClickOutside = () => {
    this.setState({
      showDropDown: false
    });
  };

  render() {
    return (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown">
          <a
            className="nav-link"
            data-toggle="dropdown"
            onClick={this.onProfileClick}
          >
            <i>
              <label style={{ color: "#27395A", paddingRight: 10 }}>
                {this.state.username}
              </label>
              {/*    <img*/}
              {/*        src={"./images/user.jpeg"}*/}
              {/*        alt="User Avatar"*/}
              {/*        style={{*/}
              {/*            borderRadius: "50%",*/}
              {/*            height: 30,*/}
              {/*            width: 30,*/}
              {/*            marginBottom: 5,*/}
              {/*        }}*/}
              {/*    />*/}

              <svg viewBox="0 0 120 100">
                <path
                  d="M38,2
         L82,2
         A12,12 0 0,1 94,10
         L112,44
         A12,12 0 0,1 112,56
         L94,90
         A12,12 0 0,1 82,98
         L38,98
         A12,12 0 0,1 26,90
         L8,56
         A12,12 0 0,1 8,44
         L26,10
         A12,12 0 0,1 38,2"
                />
                <text x="40" y="60" className="initials">
                  {this.state.initials}
                </text>
              </svg>
            </i>
          </a>
          {/* <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right"> */}
          <div
            className={classNames("dropdown-menu dropdown-menu-right", {
              show: this.state.showDropDown
            })}
            style={{
              marginTop: 10,
              minWidth:
                this.state.onOrganizations || getAuth().userType === 0
                  ? "20rem"
                  : "10rem"
            }}
          >
            {getAuth().userType === USER_TYPES.SUPER_ADMIN && (
              <div className="dropdown-item" onClick={this.onSystemAdminClick}>
                <div className="row">
                  <img
                    src={
                      this.state.onOrganizations
                        ? "./images/minimize.svg"
                        : "./images/settings.svg"
                    }
                    alt=""
                    style={{ height: 20 }}
                  />
                  <h3
                    className="dropdown-item-title"
                    style={{ padding: 1, paddingLeft: 15, color: "#666666" }}
                  >
                    {this.state.onOrganizations
                      ? "Switch to Application Experience"
                      : "System Administration"}
                  </h3>
                </div>
              </div>
            )}

            {!this.props.location.pathname.includes("/admin/organizations") && (
              <>
                <div
                  className="dropdown-item"
                  onClick={this.onProfileSettingsClick}
                >
                  <div className="row">
                    <img
                      src="./images/user.svg"
                      alt=""
                      style={{ height: 20 }}
                    />
                    <h3
                      className="dropdown-item-title"
                      style={{ padding: 1, paddingLeft: 15, color: "#666666" }}
                    >
                      My Profile
                    </h3>
                  </div>
                </div>
                <div className="dropdown-item" onClick={this.onFAQClick}>
                  <div className="row">
                    <img
                      src="./images/help-circle.svg"
                      alt=""
                      style={{ height: 20 }}
                    />
                    <h3
                      className="dropdown-item-title"
                      style={{ padding: 1, paddingLeft: 15, color: "#666666" }}
                    >
                      FAQ
                    </h3>
                  </div>
                </div>
              </>
            )}

            <div className="dropdown-item" onClick={this.logOut}>
              <div className="row">
                <img src="./images/log-out.svg" alt="" style={{ height: 20 }} />
                <h3
                  className="dropdown-item-title"
                  style={{ padding: 1, paddingLeft: 15, color: "#666666" }}
                >
                  Log Out
                </h3>
              </div>
            </div>
          </div>
        </li>
      </ul>
    );
  }
}

export const Profile = onClickOutside(Dropdown);
