import React, { useEffect, useState } from "react";
import DataGrid from "../shared/data-grid/data-grid";
import PaginationComponent from "../shared/pagination/pagination";
import { useEntitlements } from "./hooks/useEntitlements";
import { UserEntAllocation } from "./userEntitlementsAllocation/UserEntAllocation";

export const UserEntitlement = ({ getData, data }) => {
  const { usersColumns, userAllocation, setUserAllocation } = useEntitlements();

  // const [usersData, setUsersData] = useState({});

  // TODO - replace this with get all users
  // NOTE to @Luisa - /organization endpoints are only reserved for organization related work only!!!
  // You should be using v2/users endpoint to fetch data here

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageClick = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const val = (currentPage - 1) * itemsPerPage;
    getData(val, itemsPerPage);
  }, [currentPage]);

  return (
    <>
      <div style={{ marginTop: "1.5em" }}>
        <DataGrid
          data={data.data ? data.data : []}
          columns={usersColumns}
          showPagination={false}
          onRowClick={() => {}}
        />

        <PaginationComponent
          itemsPerPage={itemsPerPage}
          totalItems={data?.recordCount}
          currentPage={currentPage}
          onPageClick={handlePageClick}
        />
      </div>

      {userAllocation.state && (
        <UserEntAllocation
          open={userAllocation.state}
          userId={userAllocation.userId}
          close={() => setUserAllocation({ state: false, userId: 0 })}
        />
      )}
    </>
  );
};
