import React from "react";
import "../createStaff.scss";
import S3 from "react-aws-s3";
import { s3config } from "../../../../constants/appConstants";
import ConfirmationBox from "../../../shared/alert-box/confirmation-box/confirmation-box";
import PopUpModal from "../../../shared/popup-modal/popUpModal";

class Files extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      showLoader: false,
      documentKey: "",
    };
  }

  handleFileChange = (event) => {
    const success = true;
    s3config.dirName = this.props.directoryName;
    const ReactS3Client = new S3(s3config);
    let file = this.uploadInput.files[0];
    // Split the filename to get the name and type
    let fileParts = this.uploadInput.files[0].name.split(".");
    let fileName = fileParts[0];
    let fileType = fileParts[1];
    let newFileName = fileName + Date.now();
    this.setState({
      showLoader: true,
    });
    ReactS3Client.uploadFile(file, newFileName)
      .then((data) => {
        let file = {};
        file.name = fileName[0].toUpperCase() + fileName.slice(1);
        file.documentKey = newFileName;
        file.fileType = fileType;
        file.url = data.location;
        console.log("File uploaded.");
        this.props.onFileUpload(file, success);
        this.setState({
          showLoader: false,
        });
        document.getElementById("fileUpload").value = "";
      })
      .catch((err) => {
        console.log("File upload fail.");
        console.log(err);
        this.props.onFileUpload(null, !success);
        this.setState({
          showLoader: false,
        });
        document.getElementById("fileUpload").value = "";
      });
  };

  getFileType = (value) => {
    return value.toUpperCase();
  };

  getFileName = (value) => {
    if (value.length > 10) {
      value = value.substring(0, 10) + "...";
    }
    return value;
  };

  removeImage = () => {
    this.props.onRemoveImage(this.state.documentKey);
    this.setState({
      openModal: false,
      documentKey: "",
    });
  };

  getFileIcon = (value) => {
    switch (value.toLowerCase()) {
      case "pdf":
        return "/images/pdf.png";
      case "docx":
      case "doc":
        return "/images/doc.png";
      case "ppt":
      case "pptx":
        return "/images/doc.png";
      case "xls":
      case "xlsm":
      case "xlsx":
        return "/images/excel.png";
      case "png":
        return "/images/doc.png";
      case "jpeg":
        return "/images/doc.png";
      default:
        return "/images/doc.png";
    }
  };

  onTrashClick = (file) => {
    this.setState({
      openModal: true,
      documentKey: file.documentKey,
    });
  };

  render() {
    const fileList = this.props.fileList ? this.props.fileList : [];
    return (
      <div>
        <div className="row">
          <span className="form-title" style={{ paddingBottom: 10 }}>
            Files
          </span>
        </div>
        <div className="row">
          <div className="row col-md-8">
            {fileList.map((file, i) => {
              return (
                <div className="col-lg-4 col-md-3 col-xs-6" key={i}>
                  <div className="row col-md-12 file-component">
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="card__corner">
                        <div className="card__corner-triangle"></div>
                      </div>
                      <span className="col-sm-12">
                        <span>
                          <img
                            src={this.getFileIcon(file.fileType)}
                            alt=""
                            className=" file-icon"
                          />
                        </span>
                      </span>
                      <div className="col-sm-12 document-name">
                        {this.getFileName(file.name)}
                      </div>
                      <div className="col-sm-12 document-type">
                        {this.getFileType(file.fileType)}
                      </div>
                    </a>
                  </div>
                  <div className="row" style={{ marginBottom: 10 }}>
                    <span
                      className="col-sm-1 offset-sm-8"
                      style={{ height: 20 }}
                    >
                      <img
                        src="/images/trash-sm.svg"
                        alt=""
                        className=" document-options"
                        onClick={() => {
                          this.onTrashClick(file);
                        }}
                      />
                    </span>
                    {/* <span className="col-sm-1" style={{ height: 20 }}>
                      <img
                        src="/images/edit-sm.svg"
                        alt=""
                        className=" document-options"
                      />
                    </span> */}
                  </div>
                </div>
              );
            })}

            <div className="col-lg-4 col-md-3 col-xs-6">
              {!this.state.showLoader && (
                <div className="row col-md-12 add-document text-center">
                  <div className="row"></div>
                  <label htmlFor="fileUpload" className="add-button">
                    + Add
                  </label>
                  <input
                    id="fileUpload"
                    onChange={(e) => {
                      this.handleFileChange(e);
                    }}
                    ref={(ref) => {
                      this.uploadInput = ref;
                    }}
                    type="file"
                  />
                </div>
              )}
              {this.state.showLoader && (
                <div className="row col-md-12 add-document text-center">
                  <img
                    src="/images/loader.svg"
                    className="loader-file"
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {this.state.openModal && (
          <PopUpModal
            show={this.state.openModal}
            onClose={() => {
              this.setState({ openModal: false });
            }}
            title={"Are you sure you want to delete this file?"}
            icon={"/images/alert-circle.svg"}
            size={"md"}
            id={"deleteModal"}
          >
            <ConfirmationBox
              description={"some text"}
              onConfirm={this.removeImage}
              onCloseModal={() => {
                this.setState({ openModal: false });
              }}
            />
          </PopUpModal>
        )}
      </div>
    );
  }
}

export default Files;
