import React, { useEffect } from "react";
import "./App.scss";
import { Provider } from "react-redux";
import store from "./redux/store";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Login from "./components/login/login";
import VerifyEmail from "./components/reset-password/verify-email/verifyEmail";
import ChangePassword from "./components/reset-password/resetPassword";
import AppContainer from "./containers/appContainer";
import "react-table/react-table.css";
import { NotificationContainer } from "react-notifications";
import { ProtectedRoute } from "./components/shared/protected-route/protectedRoute";
import LoadingIndicator from "./components/shared/loader/loader";
import "semantic-ui-css/semantic.min.css";
import FAQ from "./components/faq/faq";
import VerifyOTP from "./components/two-factor-authentication/verify-otp";
import SaveBrowser from "./components/two-factor-authentication/save-browser";
import { getQueryParameter } from "./util/url";
import { startImpersonation } from "./api/organizations";
import { isImpersonationInProgress, setImpersonationData } from "./util/auth";

function App() {

// todo - capture this scenario
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //
  //     let isImpersonation = isImpersonationInProgress();
  //     if (isImpersonation){
  //
  //     }
  //   };
  //
  //   // Add the event listener
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //
  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  return (
    <div>
      <LoadingIndicator />

      <Provider store={store}>
        <BrowserRouter>
          <div className="App">
            <Switch>
              <Route exact path="/impersonate" component={ImpersonationHandler} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/verify-email" component={VerifyEmail} />
              <Route exact path="/change-password" component={ChangePassword} />
              <Route exact path="/verify-otp" component={VerifyOTP} />
              <Route exact path="/save-browser" component={SaveBrowser} />
              <ProtectedRoute path="/" component={AppContainer} />
            </Switch>
          </div>
        </BrowserRouter>
        <NotificationContainer />
      </Provider>
    </div>
  );
}

export default App;


class ImpersonationHandler extends React.Component {


  componentDidMount() {
    const user = getQueryParameter("user");
    const signature = getQueryParameter("signature");
    const token = getQueryParameter("tok");
    startImpersonation(token, user, signature).then((res) => {
      setImpersonationData(res.data.user, res.data.auth);
    });
  }

  render() {
    return <>
      <p>Redirecting.....</p>
    </>;
  }
}

