import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "../../shared/modal/Modal";
import {
  ContArrows,
  ContOneOption,
  ContOptions,
  ContStaff,
  SubTitle,
} from "../styles";
import { Input } from "../../organizations/styles";
import { getAllUsersList } from "../../../api/user";
import { getUsersGroupsById, saveUsersGroups } from "../../../api/entitlements";
import { createNotification } from "../../../util/notificationManager";
import { ALERT_TYPES } from "../../../constants/appConstants";

export const StaffAllocation = ({ open, close, groupId }) => {
  const [staffAllSelected, setStaffAllSelected] = useState([]);
  const [staffAssignedSelected, setStaffAssignedSelected] = useState([]);
  const [staffAssigned, setStaffAssigned] = useState([]);
  const [allStaff, setAllStaff] = useState([]);
  const [searchTermAllStaff, setSearchTermAllStaff] = useState("");
  const [searchTermAssignedStaff, setSearchTermAssignedStaff] = useState("");

  const allStaffFiltered = useMemo(() => {
    return allStaff
      .filter((item) => !staffAssigned.includes(item.userId))
      .filter((item) =>
        item.name.toLowerCase().includes(searchTermAllStaff.toLowerCase()),
      );
  }, [allStaff, searchTermAllStaff]);

  const staffAssignedFiltered = useMemo(() => {
    return staffAssigned.filter((item) =>
      item.name.toLowerCase().includes(searchTermAssignedStaff.toLowerCase()),
    );
  }, [staffAssigned, searchTermAssignedStaff]);

  //Functions
  const addAssignedStaff = () => {
    const temp = [...staffAllSelected, ...staffAssigned];
    setStaffAssigned(temp);
    const filteredData = allStaff.filter(
      (item) =>
        !staffAllSelected.some((element) => element.userId === item.userId),
    );
    setAllStaff(filteredData);
    setStaffAllSelected([]);
  };

  const addAllStaff = () => {
    const temp = [...staffAssignedSelected, ...allStaff];
    setAllStaff(temp);
    const filteredData = staffAssigned.filter(
      (item) =>
        !staffAssignedSelected.some(
          (element) => element.userId === item.userId,
        ),
    );
    setStaffAssigned(filteredData);
    setStaffAssignedSelected([]);
  };

  const addStaffAllSelected = (object) => {
    const index = staffAllSelected.findIndex(
      (item) => item.userId === object.userId,
    );
    if (index !== -1) {
      const newData = [...staffAllSelected];
      newData.splice(index, 1);
      setStaffAllSelected(newData);
    } else {
      setStaffAllSelected((prevData) => [...prevData, object]);
    }
  };

  const addStaffAssignedSelected = (object) => {
    const index = staffAssignedSelected.findIndex(
      (item) => item.userId === object.userId,
    );
    if (index !== -1) {
      const newData = [...staffAssignedSelected];
      newData.splice(index, 1);
      setStaffAssignedSelected(newData);
    } else {
      setStaffAssignedSelected((prevData) => [...prevData, object]);
    }
  };

  const saveChanges = () => {
    const data = {
      group: groupId,
      ids: staffAssigned.map((item) => item.userId),
    };

    saveUsersGroups(data)
      .then((res) => {
        if (res.status === 200) {
          // createNotification(
          //   ALERT_TYPES.SUCCESS,
          //   "Entitlement has been updated"
          // );
          close();
        }
      })
      .catch((e) => {
        createNotification(ALERT_TYPES.ERROR, e);
      });
  };
  //////////

  useEffect(() => {
    getUsersGroupsById(groupId)
      .then((res) => {
        setAllStaff(
          res.all,
          // res?.assigned?.length === 0
          //   ? res.all
          //   : res.all.filter((item) => !res.assigned.includes(item.userId))
        );
        const assignedStaff = res.all.filter((item) =>
          res.assigned.includes(item.userId),
        );
        setStaffAssigned(assignedStaff);
      })
      .catch((e) => {
        console.log("Error", e);
      });
  }, []);

  return (
    <Modal
      open={open}
      close={close}
      title="Staff Allocation - Staff Management"
      width="max-content"
    >
      <ContStaff>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <SubTitle center className="mb-3">
            All Staff
          </SubTitle>

          <div className="input-box" style={{ padding: 0 }}>
            <Input
              style={{ left: 0, marginBottom: "0 !important" }}
              type="text"
              className="box text-box search-icon"
              placeholder="Search"
              onChange={(e) => setSearchTermAllStaff(e.target.value)}
            />
          </div>

          <ContOptions>
            {allStaffFiltered.map((user, i) => (
              <ContOneOption
                selected={staffAllSelected.includes(user)}
                onClick={() => addStaffAllSelected(user)}
              >
                <SubTitle key={user.userId}>{user.name}</SubTitle>
              </ContOneOption>
            ))}
          </ContOptions>
        </div>

        <ContArrows>
          <img
            src="./images/chevron-right-grey.svg"
            alt=""
            onClick={addAssignedStaff}
          />
          <img
            src="./images/chevron-left-grey.svg"
            alt=""
            onClick={addAllStaff}
          />
        </ContArrows>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <SubTitle center className="mb-3">
            Assigned Staff
          </SubTitle>

          <div className="input-box" style={{ padding: 0 }}>
            <Input
              style={{ left: 0, marginBottom: "0 !important" }}
              type="text"
              className="box text-box search-icon"
              placeholder="Search"
              onChange={(e) => setSearchTermAssignedStaff(e.target.value)}
            />
          </div>

          <ContOptions>
            {staffAssignedFiltered.map((user, i) => (
              <ContOneOption
                selected={staffAssignedSelected.includes(user)}
                onClick={() => addStaffAssignedSelected(user)}
              >
                <SubTitle key={user.userId}>{user.name}</SubTitle>
              </ContOneOption>
            ))}
          </ContOptions>
        </div>
      </ContStaff>

      <div className="row justify-content-center mt-5">
        <button className="button submit col-3 mr-4" onClick={saveChanges}>
          <span className="text">SAVE</span>
        </button>

        <button className="button basic col-3" onClick={close}>
          CANCEL
        </button>
      </div>
    </Modal>
  );
};
