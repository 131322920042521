import React, { useState } from "react";
import { ContGridLocation, Details, InputRelative, Labels } from "../../styles";
import ClickableMapContainer from "../../../shared/map-container/clickableMapContainer";
import { EditLocation } from "./EditLocation";
import { useParams } from "react-router-dom";
import { forEach } from "lodash";
import { STATES_LIST, TIME_ZONES } from "../../../../constants/appConstants";
import { useEffect } from "react";

export const Location = ({ locDetails, setEdit }) => {
  const [editModal, setEditModal] = useState(false);
  const [timezoneName, setTimezoneName] = useState("N/A");
  const [state, setState] = useState("N/A");

  const { idOrg } = useParams();

  useEffect(() => {
    TIME_ZONES.forEach((item) => {
      if (item.value == locDetails?.timezone) {
        setTimezoneName(item.type);
      }
    });
    STATES_LIST.forEach((item) => {
      if (item.value == locDetails?.state) {
        setState(item.type);
      }
    });
  });

  return (
    <>
      <div style={{ marginTop: "2em" }}>
        <div className="row">
          <div className="col-5">
            <div className="row">
              <Details style={{ fontSize: 22 }}>
                {locDetails?.name ?? "N/A"}
              </Details>
              <img
                src="./images/edit-green.svg"
                style={{ height: 20, marginLeft: "1em" }}
                onClick={() => setEditModal(true)}
              />
            </div>

            <ContGridLocation>
              <InputRelative>
                <Labels>Address</Labels>
                <Labels content>{locDetails?.address ?? "N/A"}</Labels>
              </InputRelative>

              <InputRelative>
                <Labels>State/Province</Labels>
                <Labels content>{state}</Labels>
              </InputRelative>

              <InputRelative>
                <Labels>ZIP Code</Labels>
                <Labels content>{locDetails?.zipCode ?? "N/A"}</Labels>
              </InputRelative>

              <InputRelative>
                <Labels>Time Zone</Labels>
                <Labels content>{timezoneName}</Labels>
              </InputRelative>
            </ContGridLocation>
          </div>

          <div className="col-8" style={{ height: "18em", maxWidth: "36em" }}>
            <ClickableMapContainer />
          </div>
        </div>
      </div>

      {editModal && (
        <EditLocation
          open={editModal}
          close={() => {
            setEditModal(false);
            setEdit();
          }}
          idOrg={idOrg}
          locDetails={locDetails}
          setEdit={setEdit}
        />
      )}
    </>
  );
};
