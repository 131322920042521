import React from "react";
import {Field} from "redux-form";
import {inputField} from "../../../shared/form-fields/formFields";

class SystemInfo extends React.Component {
    render() {
        return (
            <div>
                <div className="row">
                    <span className="form-title">System Information</span>
                </div>
                <div className="row">
                    <div className="col-6 input-box text-left">
                        <div className="label">Organization Authentication</div>
                        <Field
                            name="orgAuthentication"
                            component={inputField}
                            type="text"
                            placeholder={"Authentication"}
                            required={true}
                        />
                    </div>
                    <div className="col-6 input-box text-left my-auto">
                        <input
                            type="checkbox"
                            name="isAuthorized"
                            value="authorized"
                            checked={this.props.isAuthorized}
                            style={{marginRight: 5}}
                            onChange={(event) => {
                                this.props.onIsAuthorized(event.target.checked);
                            }}
                        />
                        <span className="label">Authorized</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default SystemInfo;
