import React from "react";
import { Pagination } from "@mui/material";
import "./paginationStyles.css";

const PaginationComponent = ({
  itemsPerPage,
  totalItems,
  currentPage,
  onPageClick,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="cont-pagination">
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={onPageClick}
        shape="rounded"
        variant="outlined"
        size="large"
      />
    </div>
  );
};

export default PaginationComponent;
