import React from 'react';

const ConfirmNewService = ({ service }) => {
  return (
    <div>
      <h2>JSON Object:</h2>
      <ul>
        {Object.keys(service).map(key => (
          <li key={key}>
            <strong>{key}:</strong> {JSON.stringify(service[key])}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ConfirmNewService;