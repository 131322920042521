import * as React from "react";
import "./side-navigation.scss";
import { withRouter } from "react-router-dom";
import menuItems from "./menu";
import { includes, map } from "lodash";
import { getAuth } from "../../../util/auth";
import { resetSearchConfigs } from "../../../redux/config/configAction";
import { connect } from "react-redux";
import menuReports from "./menuReports";
import { USER_TYPES } from "../../../constants/user";
import { isAnyAllowed } from "../../../util/entitlementManager";

class SideNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "clients",
      expanded: false,
    };
  }

  onMenuChange = (selectedMenu) => {
    this.setState({
      selected: selectedMenu,
    });
    this.props.dispatch(resetSearchConfigs());
    this.props.history.push(`/${selectedMenu}`);
  };

  render() {
    return (
      <aside
        className="main-sidebar sidebar-light-primary"
        style={{
          marginTop: 60,
          zIndex: 10,
          boxShadow: "5px 5px 25px 0 rgba(179,179,179,0.2)",
        }}
      >
        <nav className="mt-2" style={{ marginTop: "5rem" }}>
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li
              className="nav-item"
              style={{ cursor: "pointer" }}
              onClick={() => {
                document.body.classList.toggle(
                  "sidebar-collapse",
                  !this.state.expanded,
                );
                if (document.body.classList.contains("sidebar-open")) {
                  document.body.classList.remove("sidebar-open");
                  document.body.classList.add("sidebar-closed");
                } else {
                  document.body.classList.add("sidebar-open");
                  document.body.classList.remove("sidebar-closed");
                }
                this.setState({ expanded: !this.state.expanded });
              }}
            >
              {/*<a*/}
              {/*    className="nav-link"*/}
              {/*    data-widget="pushmenu"*/}
              {/*    href="#"*/}
              {/*    role="button"*/}
              {/*    */}
              {/*>*/}
              {/*    <i className="fas fa-bars"></i>*/}
              {/*</a>*/}
              <div className="nav-link" style={{ marginBottom: 30 }}>
                <i>
                  {this.state.expanded && (
                    <img
                      src={"./images/chevron-right-green.svg"}
                      alt=""
                      style={{ marginLeft: -5 }}
                    />
                  )}
                  {!this.state.expanded && (
                    <img
                      src={"./images/chevron-left-green.svg"}
                      alt=""
                      style={{ marginLeft: -5 }}
                    />
                  )}
                  <img
                    src={"./images/menu-green.svg"}
                    alt=""
                    style={{ marginLeft: -5 }}
                  />
                </i>
              </div>
            </li>

            {this.props.location.pathname.includes("/admin/organizations") &&
            getAuth().userType === USER_TYPES.SUPER_ADMIN ? (
              <li
                className="nav-item"
                style={{ height: 64 }}
                onClick={() => this.onMenuChange("admin/organizations")}
              >
                <div className="nav-link">
                  <div className="row">
                    <div className="col-1">
                      <i>
                        <img src="./images/organizations.svg" alt="" />
                      </i>
                    </div>
                    <div className="col-11">
                      <p className="nav-bar-item">ORGANIZATIONS</p>
                    </div>
                  </div>
                </div>
              </li>
            ) : (
              <>
                {map(menuItems, (item) => {
                  if (isAnyAllowed(item.entitlements)) {
                    return (
                      <>
                        <li
                          className="nav-item"
                          style={{ height: 64 }}
                          key={item.name}
                          onClick={() => {
                            this.onMenuChange(item.url);
                          }}
                        >
                          <div className="nav-link">
                            <div className="row">
                              <div className="col-1">
                                <i>
                                  <img
                                    src={
                                      this.state.selected === item.url
                                        ? item.selectedIcon
                                        : item.icon
                                    }
                                    alt=""
                                  />
                                </i>
                              </div>
                              <div className="col-11">
                                <p
                                  className="nav-bar-item"
                                  style={
                                    this.state.selected === item.url &&
                                    item.url !== "staff/reports"
                                      ? { color: "#35cc62" }
                                      : { color: "#b0b9c9" }
                                  }
                                >
                                  {" "}
                                  {item.name}{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </>
                    );
                  }
                })}
              </>
            )}

            {this.state.selected === "staff/reports" ||
            this.state.selected === "service/reports" ? (
              <>
                {map(menuReports, (item) => {
                  if (includes(item.authUsers, getAuth().userType)) {
                    return (
                      <>
                        <li
                          className="nav-item"
                          style={{ height: 64, marginLeft: "2em" }}
                          key={item.name}
                          onClick={() => {
                            this.onMenuChange(item.url);
                          }}
                        >
                          <div className="nav-link">
                            <div className="row">
                              <div className="col-1">
                                <i>
                                  <img
                                    src={
                                      this.state.selected === item.url
                                        ? item.selectedIcon
                                        : item.icon
                                    }
                                    alt=""
                                  />
                                </i>
                              </div>
                              <div className="col-11">
                                <p
                                  className="nav-bar-item"
                                  style={
                                    this.state.selected === item.url
                                      ? { color: "#35cc62" }
                                      : { color: "#b0b9c9" }
                                  }
                                >
                                  {" "}
                                  {item.name}{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </>
                    );
                  }
                })}
              </>
            ) : null}
          </ul>
        </nav>
      </aside>
    );
  }
}

const mapStateToProps = (state) => ({});
export default withRouter(connect(mapStateToProps)(SideNavigation));
