import { useFormContext } from "react-hook-form";
import {
  Input,
  RequiredField,
  Select,
  TextArea,
} from "../../organizations/styles";
import React from "react";

export const SelectForm = ({ error, name, required, options }) => {
  const { register } = useFormContext();

  return (
    <>
      <Select {...register(name, { required: required })} error={error}>
        {options.map((record) => {
          return <option value={record.type}>{record.type}</option>;
        })}
      </Select>

      {error && (
        <RequiredField className="text-right">
          {error.type === "required" && "This field is required"}
        </RequiredField>
      )}
    </>
  );
};

export const InputForm = ({ error, type, name, required, onChange }) => {
  const { register } = useFormContext();

  return (
    <>
      <Input
        {...register(name, { required: required })}
        error={error}
        type={type}
        onChange={onChange}
      />

      {error && (
        <RequiredField className="text-right">
          {error.type === "required" && "This field is required"}
        </RequiredField>
      )}
    </>
  );
};

export const TextAreaForm = ({ error, name, required, placeholder }) => {
  const { register } = useFormContext();
  return (
    <>
      <TextArea
        {...register(name, { required: required })}
        error={error}
        placeholder={placeholder}
      />

      {error && (
        <RequiredField className="text-right">
          {error.type === "required" && "This field is required"}
        </RequiredField>
      )}
    </>
  );
};
