import { getAuthData } from "./auth";

export function isAuthorized(entitlement) {
  return true
  // const authData = JSON.parse(getAuthData());
  // const val = authData.user_entitlements?.includes(entitlement);
  // // console.log("authData", val, authData.user_entitlements, entitlement);
  // return val;
}

export function isAnyAllowed(entitlements) {

  if (entitlements?.length === 0 ) {
    return true
  }

  // console.log("entitlements", entitlements);
  for (let i = 0; i < entitlements?.length; i++) {
    if (isAuthorized(entitlements[i])) {
      console.log(
        "entitlement : ",
        entitlements[i],
        "authorized for user :",
        JSON.parse(getAuthData()).user_entitlements,
      );
      return true;
    }
  }
}
