import React, { useEffect, useState } from "react";
import BasicHeader from "../../../shared/data-grid/custom-headers/basic-header";
import BasicCell from "../../../shared/data-grid/custom-cells/basic-cell";
import DataGrid from "../../../shared/data-grid/data-grid";
import "./serviceSummary.scss";
import { isMobileDevice } from "../../../../util/deviceType";
import { ServiceSummaryMobile } from "./serviceSummaryMobile";
import { getServiceSummary } from "../../../../api/report";
import { ServiceDataTable } from "./serviceDataTable";
import PaginationComponent from "../../../shared/pagination/pagination";

export const ServiceSummary = () => {
  const [services, setServices] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const itemsPerPage = 3;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handlePageClick = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredServices = Object.entries(services).reduce(
    (result, [clientName, servicesArray]) => {
      const filteredArray = servicesArray.filter((service) => {
        return service.clientName
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });

      if (filteredArray.length > 0) {
        result[clientName] = filteredArray;
      }

      return result;
    },
    {},
  );

  const currentItems = Object.keys(filteredServices).slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  useEffect(() => {
    getServiceSummary()
      .then((res) => {
        if (res.data) {
          const temp = {};

          for (const objet of res.data) {
            const clientName = objet.clientName;

            if (!temp[clientName]) {
              temp[clientName] = [];
            }

            temp[clientName].push(objet);
          }

          setServices(temp);
        }
      })
      .catch((e) => {
        console.log("staff_Pay error", e);
      });
  }, []);

  const columns = [
    {
      Header: () => <BasicHeader header={"Service Code"} />,
      accessor: "serviceCode",
      Cell: (row) => {
        return <BasicCell value={row.value} index={1} />;
      },
    },
    {
      Header: () => <BasicHeader header={"Service End Date"} />,
      accessor: "serviceEndDate",
      Cell: (row) => <BasicCell value={row.value} index={2} />,
    },
    {
      Header: () => <BasicHeader header={"Unit Rate"} />,
      accessor: "serviceUnitRate",
      Cell: (row) => <BasicCell value={row.value} index={3} />,
    },
    {
      Header: () => <BasicHeader header={"Unit Length"} />,
      accessor: "serviceUnitLength",
      Cell: (row) => <BasicCell value={row.value} index={4} />,
    },
    {
      Header: () => <BasicHeader header={"Units Authorized"} />,
      accessor: "serviceUnitsAuthorized",
      Cell: (row) => <BasicCell value={row.value} index={5} />,
    },
    {
      Header: () => <BasicHeader header={"Units Utilized"} />,
      accessor: "unitsUtilized",
      Cell: (row) => <BasicCell value={row.value} index={6} />,
    },
    {
      Header: () => <BasicHeader header={"Units Remaining"} />,
      accessor: "unitsRemaining",
      Cell: (row) => <BasicCell value={row.value} index={7} />,
    },
    {
      Header: () => <BasicHeader header={"Allow Negative Units"} />,
      accessor: "serviceUnitsAuthorized",
      Cell: (row) => <BasicCell value={row.value} index={8} />,
    },
  ];

  return (
    <div div className="table-layout">
      <div className="row justify-content-between">
        <div className="col-lg-6 col-sm-12 page-title">Reports</div>

        <div className="col-lg-3 col-sm-12 input-box mobile-no-padding">
          <input
            type="text"
            className="box text-box search-icon"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            placeholder={"Search"}
          />
        </div>
      </div>
      <div className="cont-service-title">Service Summary Report</div>

      <>
        {currentItems.map((clientName) => (
          <>
            <div
              className={
                isMobileDevice() ? "cont-client-name-sm" : "cont-client-name"
              }
            >
              {clientName}
            </div>

            {!isMobileDevice() ? (
              <div className="cont-table">
                <DataGrid
                  data={services[clientName]}
                  columns={columns}
                  showPagination={false}
                />
              </div>
            ) : (
              <ServiceSummaryMobile data={services[clientName]} />
            )}
          </>
        ))}

        <PaginationComponent
          itemsPerPage={itemsPerPage}
          totalItems={Object.keys(filteredServices).length}
          currentPage={currentPage}
          onPageClick={handlePageClick}
        />
      </>
    </div>
  );
};
