import React, { useEffect, useState } from "react";
import { ConfirmationAlert } from "../../organizations/editOrganization/ConfirmationAlert";
import { BackgroundModal } from "../../organizations/styles";
import { Modal } from "../../shared/modal/Modal";
import {
  ContArrows,
  ContOneOption,
  ContOptions,
  ContStaff,
  SubTitle,
} from "../styles";
import { createNotification } from "../../../util/notificationManager";
import { ALERT_TYPES } from "../../../constants/appConstants";
import {
  alterEntitlementGroupForUser,
  assignEntitlementsToUser,
  getUserEntitlements,
} from "../../../api/entitlements";
import { EntitlementsOfGroup } from "./EntitlementsOfGroup";
import { pullAllWith, pullAll, clone } from "lodash";

export const UserEntAllocation = ({ open, userId, close }) => {
  const [removeEntModal, setRemoveEntModal] = useState({
    state: false,
    userId: userId,
    entId: 0,
    groupId: 0,
    groupName: "",
  });

  const [allEntitlements, setAllEntitlements] = useState([]);
  const [entitlements, setEntitlements] = useState([]);
  const [groups, setGroups] = useState([]);
  const [assigned, setAssigned] = useState([]);
  const [individualEntitlements, setIndividualEntitlements] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getUserEntitlements(userId)
      .then((res) => {
        const entList = clone(res.all);
        setAllEntitlements(entList);
        pullAllWith(res.all, res.assigned, (ent, ass) => {
          return ent.entitlementId === ass;
        });
        if (res.assigned) {
          setAssigned(res.assigned);
        }
        setEntitlements(res.all);
        if (res.groups) {
          setGroups(res.groups?.filter((g) => g.group !== -1));
          res.groups.forEach((g) => {
            if (g.group === -1) {
              setIndividualEntitlements(g.data);
            }
          });
        }
      })
      .catch((e) => {
        createNotification(ALERT_TYPES.ERROR, e);
      });
  };

  const removeGroupEntitlement = (entId, groupId) => {
    alterEntitlementGroupForUser({
      userId: userId,
      entId: entId,
      groupId: groupId,
    })
      .then((res) => {
        if (res.status === 200) {
          fetchData();
          setRemoveEntModal({
            state: false,
            groupId: 0,
            entId: 0,
            groupName: "",
          });
        }
      })
      .catch((e) => {
        createNotification(ALERT_TYPES.ERROR, e);
      });
  };

  const [groupOpenState, setGroupOpenState] = useState({});

  useEffect(() => {
    const initialGroupOpenState = {};
    groups.forEach((group) => {
      initialGroupOpenState[group.group] = false;
    });
    setGroupOpenState(initialGroupOpenState);
  }, [groups]);

  const [selectedEnts, SetSelectedEnts] = useState([]);
  const [selectedIndEnts, SetSelectedIndEnts] = useState([]);

  const selectEnt = (entId) => {
    if (selectedEnts.includes(entId)) {
      const temp = selectedEnts.filter((item) => item !== entId);
      SetSelectedEnts(temp);
    } else {
      SetSelectedEnts([...selectedEnts, entId]);
    }
  };

  const selectIndEnt = (entId) => {
    if (selectedIndEnts.includes(entId)) {
      const temp = selectedIndEnts.filter((item) => item !== entId);
      SetSelectedIndEnts(temp);
    } else {
      SetSelectedIndEnts([...selectedIndEnts, entId]);
    }
  };

  const removeEnts = () => {
    const entList = clone(allEntitlements);
    const list = individualEntitlements;
    const assignedList = assigned;
    pullAllWith(list, selectedIndEnts, (ent, id) => {
      return ent.entitlementId === id;
    });
    pullAllWith(list, selectedIndEnts, (ent, id) => {
      return ent.entitlementId === id;
    });
    pullAll(assignedList, selectedIndEnts);
    pullAllWith(entList, assignedList, (ent, id) => {
      return ent.entitlementId === id;
    });
    setEntitlements(entList);
    setIndividualEntitlements(list);
    setAssigned(assignedList);
    SetSelectedIndEnts([]);
  };

  const assignEnts = () => {
    const entList = clone(allEntitlements);
    const assignedList = assigned.concat(selectedEnts);
    console.log("assignedList", assignedList);

    let list = selectedEnts;
    individualEntitlements.forEach((ent) => {
      list.push(ent.entitlementId);
    });
    console.log("list", list);

    let newList = [];
    allEntitlements.forEach((ent) => {
      list.forEach((id) => {
        if (ent.entitlementId === id) {
          newList.push(ent);
        }
      });
    });

    console.log("newList", newList);

    pullAllWith(entList, assignedList, (ent, id) => {
      return ent.entitlementId === id;
    });
    setEntitlements(entList);
    setIndividualEntitlements(newList);
    setAssigned(assignedList);
    SetSelectedEnts([]);
  };

  const updateUserEntitlements = () => {
    let list = selectedEnts;
    individualEntitlements.forEach((ent) => {
      list.push(ent.entitlementId);
    });
    console.log("list", list);

    assignEntitlementsToUser({
      ids: list,
      userId: userId,
    })
      .then((res) => {
        if (res.status === 200) {
          close();
        }
      })
      .catch((e) => {
        createNotification(ALERT_TYPES.ERROR, e);
      });
  };

  return (
    <>
      <Modal
        open={open}
        close={close}
        title="User Entitlements Allocation"
        width="max-content"
      >
        <ContStaff>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <SubTitle center className="mb-3">
              All Entitlements
            </SubTitle>

            <ContOptions>
              {entitlements.map((item, i) => (
                <ContOneOption
                  onClick={() => selectEnt(item.entitlementId)}
                  selected={selectedEnts.includes(item.entitlementId)}
                >
                  <SubTitle key={item.entitlementId}>
                    {item.entitlement}
                    {selectedEnts.includes(item.entitlementId)}
                  </SubTitle>
                </ContOneOption>
              ))}
            </ContOptions>
          </div>

          <ContArrows>
            <img
              src="./images/chevron-right-grey.svg"
              alt=""
              onClick={() => assignEnts()}
            />
            <img
              src="./images/chevron-left-grey.svg"
              alt=""
              onClick={() => removeEnts()}
            />
          </ContArrows>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <SubTitle center className="mb-3">
              Allocated Entitlements
            </SubTitle>

            <ContOptions>
              {individualEntitlements.map((data, i) => (
                <ContOneOption
                  onClick={() => selectIndEnt(data.entitlementId)}
                  selected={selectedIndEnts.includes(data.entitlementId)}
                >
                  <SubTitle key={data.entitlementId}>
                    {data.entitlement}
                  </SubTitle>
                </ContOneOption>
              ))}
              {groups.map((item, i) => (
                <ContOneOption key={item.group}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <SubTitle>{item.groupName}</SubTitle>
                    <img
                      src={`./images/${
                        groupOpenState[item.group]
                          ? "chevron-up-grey.svg"
                          : "chevron-down-grey.svg"
                      }`}
                      alt=""
                      onClick={() => {
                        // setRemoveEntitlementsPopUp({
                        //   ...removeEntitlement,
                        //   groupId: item.groupId,
                        // });
                        setGroupOpenState((prevState) => ({
                          ...prevState,
                          [item.group]: !prevState[item.group],
                        }));
                      }}
                    />
                  </div>

                  {groupOpenState[item.group] && (
                    <EntitlementsOfGroup
                      groupId={item.group}
                      entitlements={item.data}
                      setRemoveEntitlementsPopUp={(entId) =>
                        setRemoveEntModal({
                          state: true,
                          groupId: item.group,
                          entId: entId,
                          groupName: item.groupName,
                        })
                      }
                    />
                  )}
                </ContOneOption>
              ))}
            </ContOptions>
          </div>
        </ContStaff>

        <div className="row justify-content-center mt-5">
          <button
            className="button submit col-3 mr-4"
            onClick={updateUserEntitlements}
          >
            <span className="text">SAVE</span>
          </button>

          <button className="button basic col-3" onClick={close}>
            CANCEL
          </button>
        </div>
      </Modal>

      {removeEntModal.state && (
        <BackgroundModal open>
          <ConfirmationAlert
            title="Remove Entitlement"
            description={`Removing this Entitlement will remove the user from
              “${removeEntModal.groupName}” Entitlement Group, but the user will have
  access to the rest of the Entitlements in the group. The changes will be applied immediately.`}
            img={"./images/trash.svg"}
            cancel={() => {
              setRemoveEntModal({
                state: false,
                groupId: 0,
                entId: 0,
                groupName: "",
              });
            }}
            yesBtn="PROCEED"
            save={() => {
              removeGroupEntitlement(
                removeEntModal.entId,
                removeEntModal.groupId,
              );
            }}
          />
        </BackgroundModal>
      )}
    </>
  );
};
