import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { clone, get, isEqual, isNull, orderBy, remove } from "lodash";

import "./clientDetails.scss";
import Actions from "../../shared/data-grid/custom-cells/actions";
import BasicCell from "../../shared/data-grid/custom-cells/basic-cell";
import DataGrid from "../../shared/data-grid/data-grid";
import BasicHeader from "../../shared/data-grid/custom-headers/basic-header";
import PopUpModal from "../../shared/popup-modal/popUpModal";
import ClientServiceInfo from "./clientServiceInfo";
import {
  createClientService,
  updateClientService,
} from "../../../redux/services/serviceActions";
import { NotificationManager } from "react-notifications";
import { ALERT_TYPES, DATE_FORMAT } from "../../../constants/appConstants";
import {
  getClientServiceById,
  getServiceById,
  updateStaffAllocation,
} from "../../../api/clientService";
import moment from "moment";
import StaffAllocationAction from "../../shared/data-grid/custom-cells/staffAssign";
import StaffAllocation from "./staffAllocation";

class ServiceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      selectedClientId: 0,
      activeTabIndex: 0,
      selectedServiceRequest: "",
      clientServices: [],
      isDegreeRequired: false,
      isConfirmedDates: false,
      isAllowedNegativeUnits: false,
      isServiceInfoAuthorized: false,
      AllowNegativeUnits: false,
      initialValues: null,
      isEdit: false,
      servicesList: [],
      unitLength: 0,
      overlapOption: 0,
      fileList: [],
      openDeleteModal: false,
      openAllocationModal: false,
    };
  }

  componentDidMount() {
    var { clientServices, clientId } = this.props;
    this.setState({
      clientServices: clientServices,
      selectedClientId: clientId,
    });

    // getServicesList(0, 999).then((res) => {
    //   const list = map(res.data, (obj, index) => ({
    //     key: index,
    //     text: obj.name,
    //     value: obj.serviceId,
    //   }));
    //   this.setState({
    //     servicesList: list,
    //   });
    // });
  }

  componentWillReceiveProps = (nextProps) => {
    const { history } = this.props;

    if (
      !isNull(get(nextProps, "createClientServiceSuccess")) &&
      !isEqual(
        nextProps.createClientServiceSuccess,
        this.props.createClientServiceSuccess
      )
    ) {
      this.createNotification(
        ALERT_TYPES.SUCCESS,
        "Client Service create successful"
      );
      let services = this.state.clientServices ? this.state.clientServices : [];
      let newService = nextProps.createClientServiceSuccess;
      newService.serviceStartDate = new Date(
        moment(newService.serviceStartDate).format(DATE_FORMAT)
      );

      newService.serviceEndDate = new Date(
        moment(newService.serviceEndDate).format(DATE_FORMAT)
      );
      newService.serviceStartDateDisplay = moment(
        newService.serviceStartDate
      ).format(DATE_FORMAT);
      newService.serviceEndDateDisplay = moment(
        newService.serviceEndDate
      ).format(DATE_FORMAT);
      services.push(newService);
      services = orderBy(
        services,
        [
          function (o) {
            return new Date(o.serviceStartDate);
          },
        ],
        ["desc"]
      );
      this.setState({
        clientServices: services,
        openModal: false,
      });
      this.props.onClientServicesUpdate(services);
    }

    if (
      !isNull(get(nextProps, "createClientServiceFail")) &&
      !isEqual(
        nextProps.createClientServiceFail,
        this.props.createClientServiceFail
      )
    ) {
      this.createNotification(
        ALERT_TYPES.ERROR,
        "Client Service create failed"
      );
    }

    if (
      !isNull(get(nextProps, "updateClientServiceSuccess")) &&
      !isEqual(
        nextProps.updateClientServiceSuccess,
        this.props.updateClientServiceSuccess
      )
    ) {
      let updatedService = nextProps.updateClientServiceSuccess;
      let newServicesList = remove(this.state.clientServices, function (obj) {
        return obj.serviceRequestId != updatedService.serviceRequestId;
      });

      updatedService.serviceStartDate = new Date(
        moment(updatedService.serviceStartDate).format(DATE_FORMAT)
      );
      updatedService.serviceEndDate = new Date(
        moment(updatedService.serviceEndDate).format(DATE_FORMAT)
      );

      updatedService.serviceStartDateDisplay = moment(
        updatedService.serviceStartDate
      ).format(DATE_FORMAT);
      updatedService.serviceEndDateDisplay = moment(
        updatedService.serviceEndDate
      ).format(DATE_FORMAT);
      newServicesList.push(updatedService);
      newServicesList = orderBy(
        newServicesList,
        [
          function (o) {
            return new Date(o.serviceStartDate);
          },
        ],
        ["desc"]
      );
      this.createNotification(
        ALERT_TYPES.SUCCESS,
        "Client Service update successful"
      );
      this.setState({
        clientServices: newServicesList,
        openModal: false,
      });
      this.props.onClientServicesUpdate(newServicesList);
    }

    if (
      !isNull(get(nextProps, "updateClientServiceFail")) &&
      !isEqual(
        nextProps.updateClientServiceFail,
        this.props.updateClientServiceFail
      )
    ) {
      this.createNotification(
        ALERT_TYPES.ERROR,
        "Client Service update failed"
      );
    }
  };

  createNotification(alertType, alertMessage) {
    switch (alertType) {
      case ALERT_TYPES.INFO:
        NotificationManager.info(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.SUCCESS:
        NotificationManager.success(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.WARNING:
        NotificationManager.warning(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.ERROR:
        NotificationManager.error(alertMessage, "", 2000);
        break;
      default:
        break;
    }
  }

  onEdit = (serviceId) => {
    getClientServiceById(serviceId).then((res) => {
      const selectedService = res;
      selectedService.serviceStartDate = new Date(
        moment(selectedService.serviceStartDate).utc().format(DATE_FORMAT)
      );
      selectedService.serviceEndDate = new Date(
        moment(selectedService.serviceEndDate).utc().format(DATE_FORMAT)
      );
      this.setState({
        initialValues: selectedService,
        isDegreeRequired: selectedService.isDegreeRequired,
        isConfirmedDates: selectedService.isDatesConfirmed,
        isAllowedNegativeUnits: selectedService.serviceIsNegetiveUnitsAllowed,
        isServiceInfoAuthorized: selectedService.serviceIsAuthorized,
        unitLength: selectedService.serviceUnitLength,
        isEdit: true,
        openModal: true,
        overlapOption: selectedService.overlapOption,
      });
    });
  };

  onStaffAssign = (serviceId) => {
    getClientServiceById(serviceId).then((res) => {
      this.setState({
        initialValues: res,
        openAllocationModal: true,
      });
    });
  };

  onStaffAllocationUpdate = (service, staff) => {
    let req = {
      clientId: service.clientId,
      serviceRequestId: service.serviceRequestId,
      staff: staff,
    };
    updateStaffAllocation(req).then((res) => {
      this.setState({
        openAllocationModal: false,
      });
    });
  };

  addServiceRequests = (values) => {
    const service = this.mapService(values);
    service.files = this.state.fileList;
    service.overlapOption = parseInt(this.state.overlapOption);
    if (!this.state.isEdit) {
      service.clientId = parseInt(this.state.selectedClientId);
      this.props.dispatch(createClientService(service));
    } else {
      this.props.dispatch(updateClientService(service));
    }
  };

  onServiceSelect = (event, data) => {
    let val = data.value == "" ? 0 : data.value;
    let service = this.state.initialValues;
    getServiceById(val).then((res) => {});
  };

  mapService = (serviceData) => {
    let service = {
      serviceRequestId: parseInt(get(serviceData, "serviceRequestId", "")),
      clientId: parseInt(get(serviceData, "clientId", "")),
      serviceId: parseInt(get(serviceData, "serviceId", "")),
      serviceName: get(serviceData, "serviceName", ""),
      serviceCode: get(serviceData, "serviceCode", ""),
      serviceAccount: get(serviceData, "serviceAccount", ""),
      serviceDescription: get(serviceData, "serviceDescription", ""),
      serviceUnitRate: parseFloat(get(serviceData, "serviceUnitRate", 0)),
      serviceUnitLength: parseFloat(get(serviceData, "serviceUnitLength", 0)),
      serviceStartDate: moment(
        get(serviceData, "serviceStartDate", new Date())
      ).format("YYYY-MM-DD HH:mm:ss.SSS"),
      serviceEndDate: moment(
        get(serviceData, "serviceEndDate", new Date())
      ).format("YYYY-MM-DD HH:mm:ss.SSS"),
      serviceUnitsAuthorized: parseFloat(
        get(serviceData, "serviceUnitsAuthorized", "")
      ),
      serviceIsNegetiveUnitsAllowed: this.state.isAllowedNegativeUnits,
      serviceIsAuthorized: this.state.isServiceInfoAuthorized,
      isDegreeRequired: this.state.isDegreeRequired,
      isDatesConfirmed: this.state.isConfirmedDates,
      overlapOption: this.state.overlapOption,
    };
    return service;
  };

  onRemoveFile = (documentKey) => {};

  render() {
    const columns = [
      {
        Header: () => <BasicHeader header={"Service Name"} />,
        accessor: "serviceName",
        Cell: (row) => {
          return <BasicCell value={row.value} index={row.index} />;
        },
      },
      {
        Header: () => <BasicHeader header={"Unit Rate"} />,
        accessor: "serviceUnitRate",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
        width: 100,
      },
      {
        Header: () => <BasicHeader header={"Unit Length"} />,
        accessor: "serviceUnitLength",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
        width: 100,
      },
      {
        Header: () => <BasicHeader header={"Begin Date"} />,
        accessor: "serviceStartDateDisplay",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
        width: 100,
      },
      {
        Header: () => <BasicHeader header={"End Date"} />,
        accessor: "serviceEndDateDisplay",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
        width: 100,
      },
      {
        Header: () => <BasicHeader header={"Units Authorized"} />,
        accessor: "serviceUnitsAuthorized",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
        width: 150,
      },
      {
        Header: "",
        accessor: "icon",
        Cell: (row) => (
          <StaffAllocationAction
            actionId={row.original.serviceRequestId}
            onClick={this.onStaffAssign.bind(row.original.serviceRequestId)}
            value={"Assign"}
          />
        ),
        width: 50,
      },
      {
        Header: "",
        accessor: "icon",
        Cell: (row) => (
          <Actions
            actionId={row.original.serviceRequestId}
            onEdit={this.onEdit.bind(row.original.serviceRequestId)}
          />
        ),
        width: 50,
      },
    ];
    return (
      <div className="col-lg-12 col-md-12 col-sm-12">
        {this.state.clientServices && this.state.clientServices.length > 0 && (
          <div className="row">
            <div className="col-sm-12">
              <DataGrid
                data={this.state.clientServices}
                columns={columns}
                onRowClick={(rowInfo) => {
                  this.setState({
                    selectedClient: get(rowInfo.original, "id", ""),
                  });
                }}
                onPageSizeChange={this.onPageSizeChange}
                onPageNumberChange={this.onPageChange}
                total={this.state.clientServices.length}
                length={0}
                paginationLabel={"Services per page"}
                showPagination={false}
              />
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <hr></hr>
            </div>
          </div>
        )}
        <div className="row">
          <div
            className="col-lg-12 col-md-12 col-sm-12 btn-new-service text-center"
            onClick={() => {
              this.setState({
                initialValues: null,
                isDegreeRequired: false,
                isConfirmedDates: false,
                isAllowedNegativeUnits: false,
                isServiceInfoAuthorized: false,
                openModal: true,
                isEdit: false,
              });
            }}
          >
            <span className="add-new-service">+ ADD NEW SERVICE</span>
          </div>
        </div>
        {this.state.openModal && (
          <PopUpModal
            show={this.state.openModal}
            onClose={() => {
              this.setState({ openModal: false });
            }}
            title={this.state.isEdit ? "Edit Service" : "Create Service"}
            icon={"/images/clipboard.png"}
            size={"lg"}
          >
            <ClientServiceInfo
              onSubmit={this.addServiceRequests}
              initialValues={this.state.initialValues}
              onCloseModal={() => {
                this.setState({ openModal: false });
              }}
              unitLength={this.state.unitLength}
              isDegreeRequired={this.state.isDegreeRequired}
              isConfirmedDates={this.state.isConfirmedDates}
              isAllowedNegativeUnits={this.state.isAllowedNegativeUnits}
              isServiceInfoAuthorized={this.state.isServiceInfoAuthorized}
              onConfirmDates={(value) => {
                this.setState({ isConfirmedDates: value });
              }}
              AllowNegativeUnits={(value) => {
                this.setState({ isAllowedNegativeUnits: value });
              }}
              onDegreeRequired={(value) => {
                this.setState({ isDegreeRequired: value });
              }}
              onServiceInfoAuthorized={(value) => {
                this.setState({ isServiceInfoAuthorized: value });
              }}
              onUnitLengthChange={(event) => {
                const val = parseFloat(event.target.value);
                this.setState({
                  unitLength: val,
                });
              }}
              overlapOption={this.state.overlapOption}
              onOverlapable={(event) => {
                this.setState({
                  overlapOption: event.target.value,
                });
              }}
              fileList={this.state.fileList}
              onFileUpload={(value, success) => {
                if (success) {
                  let files = clone(this.state.fileList);
                  files.push(value);
                  this.createNotification(ALERT_TYPES.INFO, "File uploaded.");
                  this.setState({
                    fileList: files,
                  });
                } else {
                  this.createNotification(
                    ALERT_TYPES.WARNING,
                    "File upload fail."
                  );
                }
              }}
              onRemoveImage={(documentKey) => {
                let files = clone(this.state.fileList);
                remove(files, function (o) {
                  return o.documentKey == documentKey;
                });
                this.setState({
                  fileList: files,
                });
              }}
            />
          </PopUpModal>
        )}
        {this.state.openAllocationModal && (
          <PopUpModal
            show={this.state.openAllocationModal}
            onClose={() => {
              this.setState({ openAllocationModal: false });
            }}
            title={"Staff Allocation"}
            icon={"/images/staff-allocation.svg"}
            size={"lg"}
          >
            <StaffAllocation
              service={this.state.initialValues}
              onUpdate={this.onStaffAllocationUpdate}
              onCloseModal={() => {
                this.setState({ openAllocationModal: false });
              }}
            />
          </PopUpModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  createClientServiceSuccess: state.service.createSuccess,
  createClientServiceFail: state.service.createFail,
  updateClientServiceSuccess: state.service.updateSuccess,
  updateClientServiceFail: state.service.updateFail,
});

export default withRouter(connect(mapStateToProps)(ServiceDetails));
