import React, { useState } from "react";
import {
  ContLogo,
  InputImage,
  InputLabel,
  InputRelative,
  Text,
  UploadLogo,
} from "../styles";
import { InputForm, TextAreaForm } from "../../shared/form-fields/Inputs";
import { useFormContext } from "react-hook-form";

export const OrganizationDetails = ({ edit, errors, orgDetails }) => {
  const [logoUrl, setLogoUrl] = useState(null);

  const { setValue } = useFormContext();

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    const reader = new FileReader();

    reader.onload = () => {
      const url = reader.result;
      setValue("logo", url);
      setLogoUrl(url);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className={edit ? "col-8" : "col-6"}>
        <InputRelative className="mb-4">
          <InputLabel>Organization Name *</InputLabel>
          <InputForm name="name" required error={errors && errors.name} />
        </InputRelative>

        <InputRelative className="mb-4">
          <InputLabel>Business Registration Number *</InputLabel>
          <InputForm
            name="brNumber"
            required
            error={errors && errors.brNumber}
          />
        </InputRelative>

        <InputRelative className="">
          <InputLabel>Description</InputLabel>
          <TextAreaForm
            name="description"
            error={errors && errors.description}
            placeholder="Introduce a short description of the organization"
          />
        </InputRelative>
      </div>

      <div className={edit ? "col-3" : "col-4"}>
        <InputLabel>Company Logo</InputLabel>

        <ContLogo>
          <UploadLogo>
            {logoUrl ? (
              <img src={logoUrl} style={{ width: "inherit" }} />
            ) : (
              <>
                <span>Upload Logo</span>

                <InputImage type="file" onChange={handleFileChange} />
              </>
            )}
          </UploadLogo>

          <Text>512 x 512 px recommended</Text>
        </ContLogo>
      </div>
    </>
  );
};
