import React from "react";
import { isMobileDevice } from "../../../util/deviceType";
import MapContainer from "../../shared/map-container/mapContainer";
import { get, startCase } from "lodash";
import { abandonVisit, completeVisit } from "../../../api/visits";
import { connect } from "react-redux";
import { resetVisitDetails } from "../../../redux/workEntries/workEntryAction";
import { getClientById } from "../../../api/client";
import { getClientServiceById } from "../../../api/clientService";
import CompleteVisit from "./complete-visit";
import AbandonVisit from "./abandon-visit";
import { createNotification } from "../../../util/notificationManager";
import { ALERT_TYPES } from "../../../constants/appConstants";
import SignaturePad from "../../shared/signature-pad";

class VisitProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visit: null,
      progress: 0,
      notes: "",
      visitDuration: 0,
      selectedClient: null,
      selectedService: null,
      serviceUnitLength: 15,
      onCompleteVisitModal: false,
      onAbandonVisitModal: false,
      signature: "",
    };
  }

  componentDidMount() {
    const visit = get(this.props, "visit", null);
    const { startedTime } = this.props;
    if (visit === null) {
      this.props.history.push("/staff/visits");
    } else {
      this.setState({ visit: visit, notes: visit.notes });

      getClientById(visit.clientId).then((res) => {
        this.setState({ selectedClient: res });
      });

      getClientServiceById(visit.serviceRequestId).then((res) => {
        this.setState({ selectedService: res });
      });

      if (visit.status === 6) {
        // visit started status
        this.setState(
          {
            visitDuration: startedTime
              ? parseInt(
                  (new Date().getTime() - new Date(startedTime).getTime()) /
                    1000
                )
              : 0,
          },
          () => {
            setInterval(() => {
              this.setState({
                visitDuration: startedTime
                  ? parseInt(
                      (new Date().getTime() - new Date(startedTime).getTime()) /
                        1000
                    )
                  : 0,
              });
            }, 1000);
            // setInterval(this.calculateVisitDuration, 1000)
          }
        );
      }
    }
  }
  manipulateVisit = () => {
    let visit = { ...this.state.visit };
    visit.notes = this.state.notes;
    visit.progress = this.state.progress;
    visit.signature = this.state.signature;
    visit.serviceStartTime = visit.serviceStartTime
      .replace("T", " ")
      .replace("Z", "");
    visit.serviceEndTime = new Date(new Date().setSeconds(0, 0))
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    return visit;
  };

  onAbandonedVisit = () => {
    const visit = this.manipulateVisit();
    abandonVisit(visit)
      .then((res) => {
        this.setState({ onAbandonVisitModal: false });
        createNotification(ALERT_TYPES.WARNING, "Visit Abandoned");
        this.props.dispatch(resetVisitDetails());

        this.props.history.push("/staff/visits");
      })
      .catch((err) => {
        createNotification(ALERT_TYPES.ERROR, err.message);
      });
  };

  onCompleteVisit = () => {
    const visit = this.manipulateVisit();

    completeVisit(visit)
      .then((res) => {
        this.setState({ onCompleteVisitModal: false });
        createNotification(ALERT_TYPES.SUCCESS, res);
        this.props.dispatch(resetVisitDetails());

        this.props.history.push("/staff/visits");
      })
      .catch((err) => {
        createNotification(ALERT_TYPES.ERROR, err.message);
      });
  };

  getVisitDuration = () => {
    let hours =
      this.state.visitDuration / 3600 > 0
        ? parseInt(this.state.visitDuration / 3600)
        : 0;
    let minuites = "";
    let seconds = "";
    if (hours > 0) {
      const remaining = this.state.visitDuration - hours * 3600;
      minuites = parseInt(remaining / 60);
      seconds = this.state.visitDuration - hours * 3600 - minuites * 60;
    } else {
      minuites = parseInt(this.state.visitDuration / 60);
      seconds = parseInt(this.state.visitDuration - minuites * 60);
    }
    return `${hours.toString().length === 2 ? hours : `0${hours}`}:${
      minuites.toString().length === 2 ? minuites : `0${minuites}`
    }:${seconds.toString().length === 2 ? seconds : `0${seconds}`}`;
  };

  calculateVisitDuration = () => {
    let visitDuration = this.state.visitDuration;
    visitDuration++;
    this.setState({ visitDuration: visitDuration });
  };

  render() {
    return (
      <div className="col-lg-10 col-md-10 col-sm-10 offset-lg-1 offset-lg-1 offset-lg-1">
        <div className="row">
          <div
            className="row col-lg-2 col-md-2 col-sm-4"
            onClick={() => {
              this.props.history.push("/staff/visits");
            }}
          >
            <img src="./images/chevron-left-green.svg" alt="" />
            <span
              style={{
                fontFamily: "Muli",
                color: "#333333",
                fontSize: 15,
                padding: 10,
              }}
            >
              Back
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-8">
            <div className="page-title">Visit Progress</div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-3 offset-lg-5 offset-md-2 offset-sm-0 text-center">
            <div
              style={{
                backgroundColor: "#c3eed0",
                color: "#35CC62",
                fontSize: 28,
                height: 42,
                paddingTop: 10,
                borderRadius: 10,
              }}
            >
              <img src="./images/clock.svg" style={{ marginRight: 10 }} />
              <span>{this.getVisitDuration()}</span>
            </div>
          </div>
        </div>

        <div className="row col-12 offset-0" style={{ marginTop: 20 }}>
          <div className="page-content" style={{ width: "100%" }}>
            <img className="banner" src="./images/banner-blue.png" alt="" />
            {this.state.selectedClient && (
              <div
                className="col-lg-12 col-md-12 col-sm-12 text-center"
                style={{ padding: 20 }}
              >
                <div className="client-details" style={{ fontSize: 21 }}>
                  {startCase(
                    `${get(this.state.selectedClient, "firstName")} ${get(
                      this.state.selectedClient,
                      "middleName"
                    )} ${get(this.state.selectedClient, "lastName")}`
                  )}
                </div>
              </div>
            )}
            {/*<div className='row'>*/}
            {/*    <div className='col-lg-4 col-md-4 col-sm-6 text-center'>*/}
            {/*        <img src="./images/map-pin.svg" alt="" style={{padding: 10, paddingRight: 0}}/>*/}
            {/*        <span className='label' style={{padding: 10}}>Address</span>*/}
            {/*        <div className='value' style={{*/}
            {/*            padding: 10,*/}
            {/*            paddingBottom: 0,*/}
            {/*            overflow: 'hidden',*/}
            {/*            textOverflow: 'ellipsis'*/}
            {/*        }}>{`${get(this.state.selectedClient, "billingStreet", "")}, ${get(this.state.selectedClient, "billingCity", "")}, ${get(this.state.selectedClient, "billingState", "")}`}</div>*/}
            {/*        <span style={{*/}
            {/*            fontSize: 12,*/}
            {/*            color: "#35cc62",*/}
            {/*            textDecoration: "underline",*/}
            {/*            cursor: "pointer"*/}
            {/*        }}*/}
            {/*              onClick={() => {*/}
            {/*                  this.setState({viewMap: !this.state.viewMap})*/}
            {/*              }}>View Client Address in Map</span>*/}
            {/*    </div>*/}
            {/*    <div className='col-lg-4 col-md-4 col-sm-6 text-center'>*/}
            {/*        <img src="./images/phone.svg" alt="" style={{padding: 10, paddingRight: 0}}/>*/}
            {/*        <span className='label' style={{padding: 10}}>Client Contact</span>*/}
            {/*        <div className='value'*/}
            {/*             style={{padding: 10}}>{get(this.state.selectedClient, "phone", "-")}</div>*/}
            {/*    </div>*/}
            {/*    <div className='col-lg-4 col-md-4 col-sm-6 text-center'>*/}
            {/*        <img src="./images/bookmark.svg" alt="" style={{padding: 10, paddingRight: 0}}/>*/}
            {/*        <span className='label' style={{padding: 10}}>Medicaid ID</span>*/}
            {/*        <div className='value'*/}
            {/*             style={{padding: 10}}>{get(this.state.selectedClient, "mci", "-")}</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
          </div>

          {this.state.viewMap && (
            <div className="page-content" style={{ width: "100%" }}>
              <div className="row" style={{ marginBottom: 10 }}>
                <div className="col-1 offset-11">
                  <span
                    style={{
                      fontSize: 12,
                      color: "#5B6576",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.setState({ viewMap: !this.state.viewMap });
                    }}
                  >
                    Hide Map
                  </span>
                </div>
              </div>
              <div className="row" style={{ marginBottom: 10 }}>
                <div
                  className="col-12"
                  style={{ height: isMobileDevice() ? "100vw" : "50vw" }}
                >
                  <MapContainer
                    address={`${get(
                      this.state.selectedClient,
                      "billingStreet",
                      ""
                    )} ${get(
                      this.state.selectedClient,
                      "billingCity",
                      ""
                    )}, ${get(
                      this.state.selectedClient,
                      "billingState",
                      ""
                    )} ${get(this.state.selectedClient, "billingCountry", "")}`}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="page-content" style={{ width: "100%" }}>
            <div className="row">
              <div className="col-12 grey-area" style={{ marginBottom: 10 }}>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="label">Progress</div>
                </div>
                <div className="row col-lg-12 col-md-12 col-sm-12">
                  <div
                    className="col-lg-4 col-md-4 col-sm-12 text-left"
                    style={{ padding: "10px" }}
                  >
                    <input
                      type="radio"
                      name="progress"
                      value="increased"
                      checked={this.state.progress == 1}
                      onChange={(event) => {
                        this.setState({ progress: 1 });
                      }}
                    />{" "}
                    Increased
                  </div>
                  <div
                    className="col-lg-4 col-md-4 col-sm-12 text-left"
                    style={{ padding: "10px" }}
                  >
                    <input
                      type="radio"
                      name="progress"
                      value="maintained"
                      checked={this.state.progress == 2}
                      onChange={(event) => {
                        this.setState({ progress: 2 });
                      }}
                    />{" "}
                    Maintained
                  </div>
                  <div
                    className="col-lg-4 col-md-4 col-sm-12 text-left"
                    style={{ padding: "10px" }}
                  >
                    <input
                      type="radio"
                      name="progress"
                      value="decreased"
                      checked={this.state.progress == 3}
                      onChange={(event) => {
                        this.setState({ progress: 3 });
                      }}
                    />{" "}
                    Decreased
                  </div>
                </div>
              </div>
              <div className="col-12" style={{ padding: 20 }}>
                <div className="visit-notes" style={{ padding: 10 }}>
                  Visit notes
                </div>
                <textarea
                  ref={(input) => {
                    this.notesText = input;
                  }}
                  className="notes-text-area"
                  placeholder="Notes"
                  value={this.state.notes}
                  onChange={(event) => {
                    this.setState({ notes: event.target.value });
                  }}
                />

                <div>
                  <SignaturePad
                    showSignaturePad={true}
                    signature={this.state.signature}
                    onChange={(signature) => {
                      this.setState({ signature });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12 offset-lg-9 offset-md-6 offset-sm-0">
                <div className="row">
                  <div className="col-6">
                    <button
                      className="button submit"
                      style={
                        this.state.progress > 0 && this.state.signature != ""
                          ? null
                          : { backgroundColor: "#B7B6B6" }
                      }
                      onClick={() => {
                        this.setState({
                          onCompleteVisitModal: true,
                        });
                      }}
                      disabled={
                        this.state.progress > 0 && this.state.signature != ""
                          ? false
                          : true
                      }
                    >
                      <span className="text">COMPLETE VISIT</span>
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="button btn-edit-black"
                      onClick={() => {
                        this.setState({ onAbandonVisitModal: true });
                      }}
                      style={{ backgroundColor: "#B7B6B6", color: "#ffffff" }}
                      disabled
                    >
                      <span className="text">ABANDON VISIT</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.onCompleteVisitModal && (
          <CompleteVisit
            show={this.state.onCompleteVisitModal}
            onClose={() => {
              this.notesText.focus();
              this.setState({ onCompleteVisitModal: false });
            }}
            visit={this.state.visit}
            client={this.state.selectedClient}
            service={this.state.selectedService}
            progress={this.state.progress}
            notes={this.state.notes}
            signature={this.state.signature}
            handleSubmit={this.onCompleteVisit}
            onCloseModal={() => {
              this.notesText.focus();
              this.setState({ onCompleteVisitModal: false });
            }}
          />
        )}

        {this.state.onAbandonVisitModal && (
          <AbandonVisit
            show={this.state.onAbandonVisitModal}
            onClose={() => {
              this.notesText.focus();
              this.setState({ onAbandonVisitModal: false });
            }}
            visit={this.state.visit}
            client={this.state.selectedClient}
            service={this.state.selectedService}
            notes={this.state.notes}
            handleSubmit={this.onAbandonedVisit}
            onCloseModal={() => {
              this.notesText.focus();
              this.setState({ onAbandonVisitModal: false });
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  visit: state.workEntry.visit,
  startedTime: state.workEntry.visitDuration,
  visitStatus: state.workEntry.visitStatus,
});
export default connect(mapStateToProps)(VisitProgress);
