import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { ConfirmationAlert } from "../../organizations/editOrganization/ConfirmationAlert";
import { BackgroundModal } from "../../organizations/styles";

export const styles = makeStyles({
  tooltip: {
    background: "#FFFFFF !important",
    color: "#666666 !important",
    borderRadius: "4px !important",
    fontSize: "14px !important",
    padding: "1em !important",
    textAlign: "center",
    fontFamily: "Muli",
    boxShadow: "0 10px 35px 0 rgba(0,0,0,0.1) !important",
  },

  arrow: {
    "&::before": {
      backgroundColor: "#FFFFFF !important",
      boxShadow: "0 10px 35px 0 rgba(0,0,0,0.1) !important",
    },
  },
});

export const EntitlementsOfGroup = ({
  entitlements,
  groupId,
  setRemoveEntitlementsPopUp,
}) => {
  const classes = styles();

  const [showMenu, setShowMenu] = useState({
    show: false,
    id: null,
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showMenu.show && !event.target.closest(".tooltip-container")) {
        setShowMenu({ ...showMenu, show: false });
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showMenu]);

  return (
    <>
      {entitlements?.map((item, i) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ padding: "0.7em" }}>{item.entitlementName}</div>
          <Tooltip
            title={
              <span
                onClick={() => {
                  setRemoveEntitlementsPopUp(item.entitlementId);
                }}
              >
                Remove Entitlement
              </span>
            }
            arrow
            placement="right"
            open={showMenu.id === i && showMenu.show}
            classes={{
              arrow: classes.arrow,
              tooltip: classes.tooltip,
            }}
          >
            <img
              src="./images/more-horizontal.svg"
              alt=""
              onClick={() =>
                setShowMenu({
                  ...showMenu,
                  show: !showMenu.show,
                  id: i,
                })
              }
            />
          </Tooltip>
        </div>
      ))}
    </>
  );
};
