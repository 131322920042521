import {isEmpty, isUndefined} from "lodash";

export function validateClientName(value) {
    return isEmpty(value) ? "Client Name is required" : undefined;
}

export function validateClientFirstName(value) {
    return isEmpty(value) ? "Client First name is required" : undefined;
}

export function validateClientLastName(value) {
    return isEmpty(value) ? "Client Last name is required" : undefined;
}

export function validateClientServiceName(value) {
    return isEmpty(value) ? "Service Name is required" : undefined;
}

export function validateStaffMember(value) {
    return isEmpty(value) ? "Staff Name is required" : undefined;
}

export function validateClient(value) {
    return isEmpty(value) ? "Client Name is required" : undefined;
}

export function validateClientServiceCode(value) {
    return isEmpty(value) ? "Service Code is required" : undefined;
}

export function validateClientServiceStartDate(value) {
    return isUndefined(value) ? "Service Start Date is required" : undefined;
}

export function validateClientServiceStartTime(value) {
    return isUndefined(value) ? "Service Start Time is required" : undefined;
}

export function validateClientServiceEndDate(value) {
    return isUndefined(value) ? "Service End Date is required" : undefined;
}

export function validateClientServiceEndTime(value) {
    return isUndefined(value) ? "Service End Time is required" : undefined;
}