import * as _ from "lodash";
import { ENTITLEMENT_URL } from "../constants/appConstants";
import { getToken } from "../util/auth";
import { get, post, put } from "../util/httpService";

export async function saveEntitlementsGroups(data) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };

  try {
    const response = await put(`${ENTITLEMENT_URL}/groups/entitlements`, data, {
      headers: headers,
    });

    return response;
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getEntitlementsGroupsById(group_id) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };

  try {
    const response = await get(
      `${ENTITLEMENT_URL}/groups/entitlements?group=${group_id}`,
      {
        headers: headers,
      },
    );
    return response.data;
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function saveUsersGroups(data) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };

  try {
    const response = await put(`${ENTITLEMENT_URL}/groups/users`, data, {
      headers: headers,
    });

    return response;
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getUsersGroupsById(group_id) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };

  try {
    const response = await get(
      `${ENTITLEMENT_URL}/groups/users?group=${group_id}`,
      {
        headers: headers,
      },
    );
    return response.data;
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function createEntitlement(data) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };

  try {
    const response = await post(`${ENTITLEMENT_URL}/group`, data, {
      headers: headers,
    });

    return response;
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function updateEntitlement(data) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };

  try {
    const response = await put(`${ENTITLEMENT_URL}/group`, data, {
      headers: headers,
    });

    return response;
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getEntitlements() {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };

  try {
    const response = await get(`${ENTITLEMENT_URL}`, {
      headers: headers,
    });
    return response.data;
  } catch (e) {
    throw get(e, "response.data", {});
  }
}

export async function getEntitlementGroups(offset) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };

  try {
    const response = await get(
      `${ENTITLEMENT_URL}/group?offset=${offset}&limit=10`,
      {
        headers: headers,
      },
    );
    return response.data;
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getUserEntitlements(userId) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(
      `${ENTITLEMENT_URL}/user/entitlements?user=${userId}`,
      {
        headers: headers,
      },
    );
    return response.data;
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function alterEntitlementGroupForUser(data) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };

  try {
    const response = await post(
      `${ENTITLEMENT_URL}/user/entitlements/alter`,
      data,
      {
        headers: headers,
      },
    );

    return response;
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function assignEntitlementsToUser(data) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };

  try {
    const response = await post(
      `${ENTITLEMENT_URL}/user/entitlements/assign`,
      data,
      {
        headers: headers,
      },
    );

    return response;
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}
