import React from "react";

export const AddCell = ({ onClick, actionId }) => {
  const handleClick = (event) => {
    onClick(actionId, event);
  };

  return (
    <div className="cell action-cell">
      <div className="col-12 text-center" onClick={handleClick}>
        <img src="/images/plus.svg" alt="Add" />
      </div>
    </div>
  );
};
