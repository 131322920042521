import React, { useEffect, useState } from "react";
import { useEntitlements } from "./hooks/useEntitlements";
import DataGrid from "../shared/data-grid/data-grid";
import PaginationComponent from "../shared/pagination/pagination";
import { CreateEntitlement } from "./CreateEntitlement";
import { StaffAllocation } from "./staffAllocation/StaffAllocation";
import { EntitlementAllocation } from "./entitlementAllocation/EntitlementAllocation";

export const EntitlementGroups = ({ data, getData }) => {
  const {
    groupsColumns,
    updateEntitlement,
    setUpdateEntitlement,
    staffAllocation,
    setStaffAllocation,
    entitlementAllocation,
    setEntitlementAllocation,
  } = useEntitlements();

  const [entitlementRowData, setEntitlementRowData] = useState({});

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageClick = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    getData(currentPage);
  }, [currentPage]);

  return (
    <>
      <div style={{ marginTop: "1.5em" }}>
        <DataGrid
          data={data?.data}
          columns={groupsColumns}
          showPagination={false}
          onRowClick={(rowInfo) => {
            setEntitlementRowData(rowInfo?.row?._original);
          }}
        />

        <PaginationComponent
          itemsPerPage={itemsPerPage}
          totalItems={data?.recordCount}
          currentPage={currentPage}
          onPageClick={handlePageClick}
        />
      </div>

      {updateEntitlement && (
        <CreateEntitlement
          edit
          open={updateEntitlement}
          close={() => setUpdateEntitlement(false)}
          getData={getData}
          data={entitlementRowData}
        />
      )}

      {staffAllocation && (
        <StaffAllocation
          groupId={entitlementRowData.entitlementGroupId}
          open={staffAllocation}
          close={() => setStaffAllocation(false)}
        />
      )}

      {entitlementAllocation && (
        <EntitlementAllocation
          groupId={entitlementRowData.entitlementGroupId}
          open={entitlementAllocation}
          close={() => setEntitlementAllocation(false)}
        />
      )}
    </>
  );
};
