import { CREATE_ORG_URL, ORGANIZATIONS_URL } from "../constants/appConstants";
import { getToken } from "../util/auth";
import { get, post, put } from "../util/httpService";
import * as _ from "lodash";

export async function getOrganizationsList(offset, searchTerm) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };

  try {
    const response = await get(
      `${ORGANIZATIONS_URL}?offset=${offset}&limit=10&filter=recent&name=${searchTerm}`,
      {
        headers: headers
      }
    );
    return response.data;
  } catch (e) {
    throw get(e, "response.data", {});
  }
}

export async function getOrganizationDetails(idOrg) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };

  try {
    const response = await get(`${ORGANIZATIONS_URL}/details?id=${idOrg}`, {
      headers: headers
    });
    return response.data;
  } catch (e) {
    throw get(e, "response.data", {});
  }
}

export async function getUsersByOrganization(offset, orgId, sort) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };

  try {
    const response = await get(
      `${ORGANIZATIONS_URL}/users?offset=${offset}&limit=10&filter=${sort}&org=${orgId}`,
      {
        headers: headers
      }
    );
    return response.data;
  } catch (e) {
    throw get(e, "response.data", {});
  }
}

export async function createOrganization(data) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };

  try {
    const response = await post(ORGANIZATIONS_URL, data, { headers: headers });

    return response;
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function editOrganization(data) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };

  try {
    const response = await put(ORGANIZATIONS_URL, data, { headers: headers });

    return response;
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function updateLocation(data) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };

  try {
    const response = await put(`${ORGANIZATIONS_URL}/location`, data, {
      headers: headers
    });

    return response;
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function updateOrgStatus(data) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };

  try {
    const response = await put(`${ORGANIZATIONS_URL}/status`, data, {
      headers: headers
    });

    return response;
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getOrgEntitlements(orgId) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(
      `${ORGANIZATIONS_URL}/entitlements?org=${orgId}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (e) {
    throw get(e, "response.data", {});
  }
}

export async function updateOrgEntitlement(data) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };

  try {
    const response = await post(
      `${ORGANIZATIONS_URL}/entitlements/enable`,
      data,
      {
        headers: headers,
      }
    );

    return response;
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function startImpersonation(authToken,user,signature) {
  const headers = { Authorization: `bearer ${authToken}` };
  const data = {
    userId : parseInt(user),
    signature: signature,
    token: authToken
  }
  try {
    const response = await post(
      `${ORGANIZATIONS_URL}/users/impersonation/start`,
      data,
      {
        headers: headers,
      }
    );
    return response;
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function stopImpersonation(user) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  const data = {
    userId : user
  }
  try {
    const response = await post(
      `${ORGANIZATIONS_URL}/users/impersonation/end`,
      data,
      {
        headers: headers,
      }
    );

    return response;
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}