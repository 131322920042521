import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { FormCard } from "../organizations/styles";
import { EntitlementGroups } from "./EntitlementGroups";
import { UserEntitlement } from "./UserEntitlement";
import { Entitlements } from "./Entitlements";
import { getEntitlementGroups } from "../../api/entitlements";
import { Menu } from "../shared/menuTabs/Menu";
import { getAllUsers } from "../../api/user";
import { useEffect } from "react";

const entitlementsUrl = "/admin/entitlements";

export const NestedRoutesEnt = ({ match }) => {
  const tabs = [
    {
      name: "Entitlement Groups",
      href: `${entitlementsUrl}/entitlement-groups`,
    },
    {
      name: "User Entitlement",
      href: `${entitlementsUrl}/user-entitlement`,
    },
  ];

  const [entitlementGroupList, setEntitlementGroupList] = useState({
    data: [],
    recordCount: null,
  });

  const [searchParam, setSearchParam] = useState("");

  useEffect(() => {
    console.log("searchParam", searchParam);
  }, [searchParam]);

  const getEntitlementGroupList = (currentPage) => {
    getEntitlementGroups((currentPage - 1) * 10)
      .then((res) => {
        if (res.data) {
          setEntitlementGroupList({
            data: res.data,
            recordCount: res.recordCount,
          });
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };

  const [usersData, setUsersData] = useState({
    data: [],
    recordCount: null,
  });

  const fetchUsersList = (currentPage, itemsPerPage) => {
    getAllUsers(currentPage, itemsPerPage, "recent", searchParam, "user", 0)
      // getUsersByOrganization(offset, orgId)
      .then((res) => {
        if (res.data) {
          const userDataWithCompleteName = res.data.map((user) => ({
            ...user,
            completeName:
              `${user.firstName} ${user.middleName} ${user.lastName}`.trim(),
          }));
          setUsersData({
            data: userDataWithCompleteName,
            recordCount: res.recordCount,
          });
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };

  return (
    <div className="table-layout">
      <Entitlements
        getData={getEntitlementGroupList}
        searchParam={searchParam}
        setSearchParam={setSearchParam}
      />

      <FormCard style={{ padding: "2em 4em" }}>
        <Menu tabs={tabs} />

        <Switch>
          <Route
            path={`${match.path}/entitlement-groups`}
            render={() => (
              <EntitlementGroups
                getData={getEntitlementGroupList}
                data={entitlementGroupList}
              />
            )}
          />
          <Route
            path={`${match.path}/user-entitlement`}
            // component={UserEntitlement}
            render={() => (
              <UserEntitlement getData={fetchUsersList} data={usersData} />
            )}
          />
        </Switch>
      </FormCard>
    </div>
  );
};
