import React from "react";
import {
  ContButtons,
  ContConfirmation,
  ContHeaderAlert,
  ContTitle,
  Labels,
  ModalCard,
  TitleOrg,
} from "../styles";

export const ConfirmationAlert = ({
  cancel,
  img = "./images/active.svg",
  description = "¿Do you want to save the changes?",
  save,
  title = "Confirmation",
  yesBtn = "SAVE",
  noBtm = "CANCEL",
}) => {
  return (
    <ModalCard alert>
      <ContConfirmation>
        <ContHeaderAlert>
          <ContTitle>
            <img src={img} style={{ width: "21px" }} />
            <TitleOrg style={{ fontSize: 22 }}>{title}</TitleOrg>
          </ContTitle>

          <div style={{ borderLeft: " 1px solid #e9e9e9", padding: "1.5em" }}>
            <img src="./images/close.svg" onClick={cancel} />
          </div>
        </ContHeaderAlert>

        <div style={{ padding: "2em 3em" }}>
          <Labels style={{ textAlign: "center" }} content>
            {description}
          </Labels>

          <ContButtons>
            <button className="button submit col-6" onClick={save}>
              <span className="text">{yesBtn}</span>
            </button>

            <button className="button basic col-6" onClick={cancel}>
              <span className="text">{noBtm}</span>
            </button>
          </ContButtons>
        </div>
      </ContConfirmation>
    </ModalCard>
  );
};
