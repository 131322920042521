import * as axios from "axios";
import { BASE_URL } from "../constants/appConstants";
import { trackPromise } from "react-promise-tracker";
import { checkAuthStatus } from "./auth";

export async function get(url, options = {}) {
  try {
    const response = await trackPromise(
      axios.get(`${BASE_URL}${url}`, options)
    );
    return response;
  } catch (e) {
    checkAuthStatus(e?.response?.status);

    throw e;
  }
}

export async function post(url, body, options = {}) {
  try {
    const response = await trackPromise(
      axios.post(`${BASE_URL}${url}`, body, options)
    );

    return response;
  } catch (e) {
    checkAuthStatus(e.response.status);
    throw e;
  }
}

export async function put(url, body, options = {}) {
  try {
    const response = await trackPromise(
      axios.put(`${BASE_URL}${url}`, body, options)
    );
    return response;
  } catch (e) {
    checkAuthStatus(e.response.status);
    throw e;
  }
}

export async function del(url, body) {
  try {
    const response = await trackPromise(
      axios.delete(`${BASE_URL}${url}`, body)
    );
    return response;
  } catch (e) {
    checkAuthStatus(e.response.status);
    throw e;
  }
}
export async function externalAPIGet(url, options = {}) {
  try {
    const response = await trackPromise(axios.get(url, options));
    return response;
  } catch (e) {
    checkAuthStatus(e.response.status);
    throw e;
  }
}
