import * as React from "react";
import { Field } from "redux-form";
import { inputField } from "../../../shared/form-fields/formFields";
import ClickableMapContainer from "../../../shared/map-container/clickableMapContainer";
import { validateRequiredFields, validateZIPCode } from "./clientValidator";
import { isEmpty } from "lodash";
import { geoCodeAddress } from "../../../../api/maps";
import { STATES_LIST } from "../../../../constants/appConstants";

class ClientAddressInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      geoCoords: {},
      location: "",
      street: "",
      city: "",
      state: "",
      country: "",
    };
  }

  fetchCoords = (street, city, state, country) => {
    let address = "";
    if (
      !isEmpty(street) &&
      !isEmpty(city) &&
      !isEmpty(state) &&
      !isEmpty(country)
    ) {
      address = street + " " + city + " " + state + " " + country;
    }
    if (address.length > 3) {
      let location = address.replace(" ", "+");
      geoCodeAddress(location)
        .then((res) => {
          if (res.length > 0) {
            let geoLocation = res[0].geometry.location;
            let coords = {};
            coords.lat = geoLocation.lat;
            coords.lng = geoLocation.lng;
            this.props.onLatLongChange(coords);
            this.setState({
              geoCoords: coords,
            });
          }
        })
        .catch((err) => {
          console.log("Geo coding error ", err);
        });
    }
  };

  streetChange = (e) => {
    this.setState({
      street: e.target.value,
    });
    this.fetchCoords(
      e.target.value,
      this.state.city,
      this.state.state,
      this.state.country,
    );
  };
  cityChange = (e) => {
    this.setState({
      city: e.target.value,
    });
    this.fetchCoords(
      this.state.street,
      e.target.value,
      this.state.state,
      this.state.country,
    );
  };
  stateChange = (e) => {
    this.setState({
      state: e.target.value,
    });
    this.props.onBillingStateChange(e);
    this.fetchCoords(
      this.state.street,
      this.state.city,
      e.target.value,
      this.state.country,
    );
  };
  countryChange = (e) => {
    this.setState({
      country: e.target.value,
    });
    this.fetchCoords(
      this.state.street,
      this.state.city,
      this.state.state,
      e.target.value,
    );
  };

  componentDidMount() {
    if (this.props.initialValues) {
      this.setState({
        street: this.props.initialValues.billingStreet,
        city: this.props.initialValues.billingCity,
        state: this.props.initialValues.billingState,
        country: this.props.initialValues.billingCountry,
      });
      this.fetchCoords(
        this.props.initialValues.billingStreet,
        this.props.initialValues.billingCity,
        this.props.initialValues.billingState,
        this.props.initialValues.billingCountry,
      );
    } else {
      this.setState({
        state: this.props.billingState,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let coords = {};
    if (nextProps.latitude && nextProps.longitude) {
      coords.lat = nextProps.latitude;
      coords.lng = nextProps.longitude;
      this.setState({
        geoCoords: coords,
      });
    } else if (nextProps.initialValues) {
      this.setState({
        street: nextProps.initialValues.billingStreet,
        city: nextProps.initialValues.billingCity,
        state: nextProps.initialValues.billingState,
        country: nextProps.initialValues.billingCountry,
      });
      this.fetchCoords(
        nextProps.initialValues.billingStreet,
        nextProps.initialValues.billingCity,
        nextProps.initialValues.billingState,
        nextProps.initialValues.billingCountry,
      );
    }
  }

  render() {
    return (
      <div>
        <div className="row">
          <span className="form-title">Address Information</span>
        </div>
        <div className="row">
          <div className="col-12 input-box text-left">
            <div className="label">Billing Street Address</div>
            <Field
              name="billingStreet"
              component={inputField}
              type="text"
              placeholder={"Billing Street Address"}
              required={true}
              validate={validateRequiredFields}
              onTextValueChange={this.streetChange}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Billing City</div>
            <Field
              name="billingCity"
              component={inputField}
              type="text"
              placeholder={"Billing City"}
              required={true}
              validate={validateRequiredFields}
              onTextValueChange={this.cityChange}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Billing State/Province</div>
            {/*<Field*/}
            {/*    name="billingState"*/}
            {/*    component={inputField}*/}
            {/*    type="text"*/}
            {/*    placeholder={"Billing State/Province"}*/}
            {/*    required={true}*/}
            {/*    validate={validateRequiredFields}*/}
            {/*    onTextValueChange={this.stateChange}*/}
            {/*/>*/}
            <select
              className="select-box"
              placeholder="Select Waiwer Type"
              onChange={this.stateChange}
              required={true}
              value={this.props.billingState}
            >
              {STATES_LIST.map((record) => {
                return <option value={record.value}>{record.type}</option>;
              })}
            </select>
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Billing Zip/Postal Code</div>
            <Field
              name="billingZIP"
              component={inputField}
              type="text"
              placeholder={"Billing Zip/Postal Code"}
              required={true}
              validate={validateZIPCode}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Billing Country</div>
            <Field
              name="billingCountry"
              component={inputField}
              type="text"
              placeholder={"Billing Country"}
              required={true}
              validate={validateRequiredFields}
              onTextValueChange={this.countryChange}
            />
          </div>

          <div className="col-12" style={{ height: "30vw" }}>
            <ClickableMapContainer coordinates={this.state.geoCoords} />
          </div>
        </div>
      </div>
    );
  }
}

export default ClientAddressInfo;
