import React, { useState } from "react";
import BasicHeader from "../../shared/data-grid/custom-headers/basic-header";
import BasicCell from "../../shared/data-grid/custom-cells/basic-cell";
import Actions from "../../shared/data-grid/custom-cells/actions";
import { AddCell } from "../../shared/data-grid/custom-cells/AddCell";
import StaffAllocationAction from "../../shared/data-grid/custom-cells/staffAssign";
import { isAnyAllowed } from "../../../util/entitlementManager";

export const useEntitlements = () => {
  const [updateEntitlement, setUpdateEntitlement] = useState(false);
  const [staffAllocation, setStaffAllocation] = useState(false);
  const [entitlementAllocation, setEntitlementAllocation] = useState(false);
  const [userAllocation, setUserAllocation] = useState({
    state: false,
    userId: 0,
  });

  const getUserType = (userType) => {
    switch (userType) {
      case 0:
        return "Super Admin";
      case 1:
        return "Admin";
      case 2:
        return "Staff";
      case 3:
        return "";

      default:
        return "User";
    }
  };

  const groupsColumns = [
    {
      Header: () => <BasicHeader header={"Entitlement Group"} />,
      accessor: "name",
      Cell: (row) => {
        return <BasicCell value={row.value} index={row.index} />;
      },
    },
    {
      Header: () => <BasicHeader header={"User Type"} />,
      accessor: "userType",
      Cell: (row) => {
        return <BasicCell value={getUserType(row.value)} index={row.index} />;
      },
    },
    {
      Header: "",
      accessor: "icon",
      Cell: (row) => {
        // todo - needs to be updated with proper entitlements
        if (!isAnyAllowed(-1)) {
          return (
            <StaffAllocationAction
              actionId={row.original.id}
              onClick={() => setStaffAllocation(!staffAllocation)}
            />
          );
        }
        return <></>;
      },
      width: 100,
    },
    {
      Header: "",
      accessor: "icon",
      Cell: (row) => (
        <AddCell
          actionId={row.original.id}
          onClick={() => setEntitlementAllocation(!entitlementAllocation)}
        />
      ),
      width: 100,
    },
    {
      Header: "",
      accessor: "icon",
      Cell: (row) => (
        <Actions
          actionId={row.original.id}
          onEdit={() => setUpdateEntitlement(!updateEntitlement)}
        />
      ),
      width: 100,
    },
  ];

  const usersColumns = [
    {
      Header: () => <BasicHeader header={"User Name"} />,
      accessor: "completeName",
      Cell: (row) => {
        return <BasicCell value={row.value} index={row.index} />;
      },
    },
    {
      Header: () => <BasicHeader header={"User Type"} />,
      accessor: "userType",
      Cell: (row) => {
        return <BasicCell value={getUserType(row.value)} index={row.index} />;
      },
    },
    {
      Header: "",
      accessor: "icon",
      Cell: (row) => (
        <AddCell
          actionId={row.original.id}
          onClick={() => {
            setUserAllocation({
              state: !userAllocation.state,
              userId: row.original.userId,
            });
          }}
        />
      ),
      width: 100,
    },
  ];

  return {
    groupsColumns,
    usersColumns,
    getUserType,
    setUpdateEntitlement,
    updateEntitlement,
    staffAllocation,
    setStaffAllocation,
    entitlementAllocation,
    setEntitlementAllocation,
    userAllocation,
    setUserAllocation,
  };
};
