import React from "react";
import "./staffType.scss";
import { getAuth } from "../../../util/auth";

class StaffType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      staffType: 2,
      showAdmin: false,
    };
  }

  onFilterChange = (event) => {
    const type = event.target.value;
    this.setState({
      staffType: type,
    });
  };

  onContinue = () => {
    this.props.history.push(
      `/admin/create-staff?staffType=${this.state.staffType}`
    );
  };

  componentDidMount() {
    // todo - use new entitlements mechanism to allow users to create admins
  }

  render() {
    const recordTypes = [
      {
        type: "Staff",
        value: 2,
        description: "A staff member of an organization.",
      },
      {
        type: "Behavioral Specialist",
        value: 3,
        description:
          "A behavioral specialist for [a] particular individual(s) with disabilities.",
      },
      {
        type: "Customer",
        value: 5,
        description: "A customer or contact related to DMS Billing LLC.",
      },
      {
        type: "Family Contact",
        value: 6,
        description:
          "A family member or important contact of an individual with a waiver",
      },
      {
        type: "Other",
        value: 7,
        description:
          "A contact that is of a type not in other categories, such as admin, lawyers, etc.",
      },
    ];

    if (this.state.showAdmin) {
      recordTypes.unshift({
        type: "Admin",
        value: 1,
        description: "Administration Account.",
      });
    }

    return (
      <div className="client">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-12 offset-sm-12">
            <div className="row" style={{ paddingBottom: 30 }}>
              <div className="col-12">
                <div className="page-title">Select Staff Record Type</div>
              </div>
            </div>
            <div className="row" style={{ paddingTop: 10, paddingBottom: 10 }}>
              <div className="col-12">
                <div className="sub-tittle">Select Staff Record Type</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <select
                  className="select-box"
                  placeholder="Select Staff Record Type"
                  value={this.state.staffType}
                  onChange={this.onFilterChange}
                >
                  {recordTypes.map((record) => {
                    return <option value={record.value}>{record.type}</option>;
                  })}
                </select>
              </div>
            </div>
            <div
              className="row"
              style={{
                marginTop: 10,
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ margin: 2 }}>
                <button className="button submit" onClick={this.onContinue}>
                  CONTINUE
                </button>
              </div>

              <div style={{ margin: 2 }}>
                <button
                  className="button basic"
                  onClick={(event) => {
                    this.props.history.push("/admin/staff");
                    event.preventDefault();
                  }}
                >
                  CANCEL
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <hr />
              </div>
            </div>
            <div className="row" style={{ padding: 10 }}>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <span className="staff-type-header">Record type name</span>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <span className="staff-type-header">Description</span>
              </div>
            </div>
            {recordTypes.map((record) => {
              return (
                <div className="row" style={{ padding: 10 }}>
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <span className="staff-type-content">{record.type}</span>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-6">
                    <span className="staff-type-content">
                      {record.description}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default StaffType;
