
import React from "react";
import { withRouter } from "react-router-dom";

class Dashboard extends React.Component {
  componentDidMount() {
  }

  render() {
    return (

      <center><p>Nothing to see here at this moment!</p></center>

    )
  }
}

export default withRouter(Dashboard);
